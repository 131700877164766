<div class="tile-item-content">
    <ng-container *ngIf="tile.isEmptyTile()">
        <!-- Empty tile -->
        <button type="button" class="ig-icon ig-button" (click)="onTileKebabClick($event)"><i class="fa fa-ellipsis-v"></i></button>
        <div class="tile-item-ct ig-clickable icon-tile empty-tile" (click)="onEmptyTileItemClick($event)">
            <div class="icon-tile-img empty-tile fa fa-plus-square-o"></div>
        </div>
        <section class="tile-item-label">
            <div class="tile-item-title ig-advisory" i18n>Empty Tile</div>
            <div class="tile-item-description ig-advisory" i18n>Click to add a new tile with important information for your team.</div>
        </section>
    </ng-container>
    <ng-container *ngIf="!tile.isEmptyTile()">
        <!-- Non-empty tile -->
        <button type="button" class="ig-icon ig-button" (click)="onTileKebabClick($event)"><i class="fa fa-ellipsis-v"></i></button>
        <div class="tile-item-ct ig-clickable" [ngClass]="{'icon-tile':iconStyleClass}"
             [style.backgroundImage]="backgroundImage" (click)="onTileItemClick($event)">
            <div *ngIf="iconStyleClass" class="icon-tile-img" [ngClass]="iconStyleClass"></div>
        </div>
        <section class="tile-item-label">
            <div class="tile-item-title" >{{tile.name}}</div>
            <div class="tile-item-description">{{tile.description}}</div>
        </section>
    </ng-container>
</div>