<div>
    <div class="p-col-12 dqp-toolbar ig-row-justify-end" style="margin:8px 0">
        <button igButton icon="fa-plus" i18n-label label="New" (click)="onNewClick($event)"></button>
    </div>
    <!------------------------------------------------------------------------------
       Table
    ------------------------------------------------------------------------------->
    <!-- Popup menu for row icon -->
    <p-menu #rowIconMenu [popup]="true" [model]="rowIconMenuItems"></p-menu>

    <p-table [value]="tableData" selectionMode="single"
             [(selection)]="selectedRowData" dataKey="name" [scrollable]="true">
        <ng-template pTemplate="header">
            <tr>
                <th [pSortableColumn]="'name'" i18n>Execution Profile Name
                    <p-sortIcon *ngIf="hasData"[field]="'name'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'executionSizing.clusterType'" i18n>Cluster Type
                    <p-sortIcon *ngIf="hasData"[field]="'executionSizing.clusterType'"></p-sortIcon>
                </th>
                <th class="col-btn"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">
                <td>{{rowData.name}}</td>
                <td>{{getClusterTypeLabel(rowData)}}</td>
                <td class="col-btn">
                    <a style="width:16px" (click)="onRowIconClick($event, rowData)">
                        <i class="fa fa-ellipsis-v"></i></a>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>