<p-menu #rowKebabMenu [popup]="true" [model]="rowKebabMenuItems" [appendTo]="'body'"></p-menu>
<p-table [value]="rows" dataKey="id" [scrollable]="true" editMode="row">
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-editing="editing">
        <tr [formGroup]="rowData">
            <td class="name-col">
                <div class="ig-field-v-ct">
                    <label i18n>Link Name</label>
                    <div class="ig-form-field ig-w-100pc">
                        <input igFormControl pInputText maxlength="250" type="text" formControlName="label"
                               [readonly]="!isRowEditable(rowData)"
                        />
                    </div>
                </div>
            </td>
            <td class="url-col">
                <div class="ig-field-v-ct">
                    <label i18n>Link URL</label>
                    <div class="ig-form-field ig-w-100pc">
                        <input igFormControl pInputText maxlength="250" type="text" formControlName="url"
                               [readonly]="!isRowEditable(rowData)"
                        />
                        <span *ifErrorForField="'url'" class="ig-feedback" i18n>Invalid URL</span>
                    </div>
                </div>
            </td>
            <td class="col-btn">
                <div class="ig-field-v-ct">
                    <label></label>
                    <div class="ig-form-field">
                        <button type="button" class="ig-icon ig-button"
                                (click)="onRowKebabClick($event, rowData, rowIndex)"><i class="fa fa-ellipsis-v"></i></button>
                    </div>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
<p-menu #defaultLinksMenu [popup]="true" [model]="defaultLinkMenuItems"></p-menu>
<div>
    <button igButton icon="fa-plus-circle"
             class="ig-link-button" i18n-label label="Add New Link"
             (click)="onAddNewLinkClick()"></button>
    <button igButton icon="fa-plus-circle"
            class="ig-link-button" i18n-label label="Add Data360 DQ+ Link"
            (click)="onAddDefaultLinkClick($event)"></button>
</div>


<!--<div>{{formArray.value | json}}</div>-->