<div class="dqp-toolbar">
    <ig-search-field class="ig-grow" (textChange)="filterPermissionTable($event)"
                     i18n-placeholder placeholder="Search Permissions..."
    ></ig-search-field>
</div>
<p-table #permissionsTable [value]="permissionsTableData" selectionMode="single"
         dataKey="id"
         class="ig-flex"
         styleClass="p-datatable-striped"
         [(selection)]="selection"
         [globalFilterFields]="['displayName']"
         [scrollable]="true"
>
    <ng-template pTemplate="header">
        <tr class="ig-plain">
            <th [pSortableColumn]="'displayName'" i18n>Permission Name
                <p-sortIcon [field]="'displayName'"></p-sortIcon>
            </th>
            <th *ngIf="showImpliedPermissionColumn"
                [pSortableColumn]="'isImplied'" i18n style="width:20%;">Implied
                <p-sortIcon [field]="'isImplied'"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
        <tr [pSelectableRow]="rowData">
            <td>{{rowData.displayName}}</td>
            <td *ngIf="showImpliedPermissionColumn"><i *ngIf="rowData.isImplied" class="fa fa-check" aria-hidden="true"></i></td>
        </tr>
    </ng-template>
</p-table>