<!--suppress HtmlFormInputWithoutLabel -->
<div class="ig-padding-s-t">
    <form [formGroup]="formGroup" (keydown.enter)="$event.preventDefault()">
        <p-accordion [multiple]="true">
            <!--
            -----------------------------------------
            General
            -----------------------------------------
            -->
            <p-accordionTab [selected]="true">
                <p-header>
                    <span i18n class="p-accordion-header-text">General</span>
                    <ig-field-error-summary [formModel]="formGroup"
                                            [formControlNames]="['name', 'type', 'promoteSecurityAction',
                                            'promoteScheduleAction', 'portalBaseUrl']">

                    </ig-field-error-summary>
                </p-header>
                <div class="ig-form-section">
                    <div class="ig-field-h-ct" *ngIf="selectedDevEnvDetail?.envId">
                        <label i18n class="ig-bold">ID</label>
                        <div>
                            <span>{{selectedDevEnvDetail.envId}}</span>
                        </div>
                    </div>
                    <div class="ig-field-v-ct">
                        <label i18n>Name</label>
                        <div class="ig-form-field ig-w300">
                            <input igFormControl pInputText formControlName="name" maxlength="250" type="text"/>
                            <span *ifErrorForField="'name';errorKey:'duplicateName'" class="ig-feedback" i18n>Name already exists</span>
                        </div>
                    </div>

                    <div class="ig-field-v-ct">
                        <label i18n>Type</label>
                        <div class="ig-form-field ig-w300">
                            <p-dropdown igFormControl formControlName="type" [options]="devEnvTypes"></p-dropdown>
                        </div>
                    </div>

                    <div class="ig-field-v-ct">
                        <label i18n>Security Information</label>
                        <div class="ig-field-h-ct">
                            <div class="ig-form-field ig-min-w300">
                                <p-dropdown formControlName="promoteSecurityAction" [options]="promoteOrImportActions">
                                </p-dropdown>
                            </div>
                            <div i18n class="ig-advisory">Choose how to handle security information when promoting or importing
                                to this environment
                            </div>
                        </div>
                    </div>

                    <div class=" ig-field-v-ct">
                        <label i18n>Scheduling Information</label>
                        <div class="ig-field-h-ct">
                            <div class="ig-form-field ig-min-w300">
                                <p-dropdown formControlName="promoteScheduleAction" [options]="promoteOrImportActions">
                                </p-dropdown>
                            </div>
                            <div i18n class="ig-advisory">Choose how to handle scheduling information when promoting or
                                importing to this environment
                            </div>
                        </div>
                    </div>

                    <div class=" ig-field-v-ct ig-stretch">
                        <label i18n>Portal URL</label>
                        <div class="ig-form-field ig-grow">
                            <input pInputText formControlName="portalBaseUrl"
                                   [class]="{ 'ig-error':portalBaseUrl.invalid}" type="text"/>
                        </div>
                    </div>
                </div>
                <div class="ig-form-section ig-margin-l-t">
                    <div class="ig-field-h-ct">
                        <p-checkbox [formControl]="formGroup.controls['allowUnmaskAllForSecureFields'] //noinspection InvalidExpressionResultType"
                                    [value]="true"
                                    [binary]="true"
                                    i18n-label label="Allow Unmask All for Secure Fields"></p-checkbox>
                    </div>
                    <div class="ig-field-h-ct">
                        <p-checkbox [formControl]="formGroup.controls['allowUnmaskSecurityFieldsInAnalysis'] //noinspection InvalidExpressionResultType"
                                    [value]="true"
                                    [binary]="true"
                                    i18n-label label="Allow Unmask for Secure Fields in Analysis"></p-checkbox>
                    </div>
                </div>
            </p-accordionTab>
            <!--
            -----------------------------------------
            Security
            -----------------------------------------
            -->
            <p-accordionTab [selected]="true" [hidden]="isNew() || !isUserOrgAdmin">
                <p-header>
                    <span i18n class="p-accordion-header-text">Security</span>
                    <ig-field-error-summary [formModel]="formGroup"
                                            [formControlNames]="'superGroups'">

                    </ig-field-error-summary>
                </p-header>
                <div class="ig-form-section">
                    <div class="ig-field-v-ct ig-stretch">
                        <label i18n>Environment Groups</label>
                        <div class="ig-field-h-ct">
                            <div class="ig-form-field ig-no-minw ig-grow">
                                <ig-multi-select formControlName="superGroups" [options]="availableSuperGroups"
                                                 optionLabel="groupName" maxSelectedLabels="5"
                                                 dataKey="id">
                                </ig-multi-select>
                            </div>
                            <button igButton i18n-label label="Manage Environment Groups" (click)="showManageEnvGroupsPopup()"></button>
                        </div>
                    </div>
                </div>
            </p-accordionTab>
            <!--
                 -----------------------------------------
                 Retention
                 -----------------------------------------
                 -->
            <p-accordionTab [selected]="true">
                <p-header>
                    <span i18n class="p-accordion-header-text">Retention</span>
                    <ig-field-error-summary [formModel]="formGroup"
                                            [formControlNames]="['defaultRetention','executionHistoryRetention']">

                    </ig-field-error-summary>
                </p-header>
                <div class="ig-form-section">
                    <div class="ig-field-h-ct" formGroupName="defaultRetention">
                        <div class="ig-field-v-ct">
                            <label i18n>Default Retention Period</label>
                            <div class="ig-field-h-ct">
                                <div class="ig-form-field ig-w175">
                                    <p-spinner igFormControl formControlName="period" min="1" max="99"></p-spinner>
                                </div>
                                <div class="ig-form-field ig-w175">
                                    <p-dropdown formControlName="periodUnit" [options]="periodOptions">
                                    </p-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="ig-field-v-ct ig-margin-l-l">
                            <label class="ig-nowrap" i18n>Period Precision</label>
                            <div class="ig-form-field ig-w175">
                                <p-dropdown formControlName="periodPrecision" [options]="periodOptions">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                    <div formGroupName="executionHistoryRetention">
                        <div class="ig-field-h-ct">
                            <div class="ig-field-v-ct">
                                <label i18n>Execution History Retention Period</label>
                                <div class="ig-field-h-ct">
                                    <div class="ig-form-field ig-w175">
                                        <p-spinner igFormControl formControlName="period" min="1" max="99"></p-spinner>
                                    </div>

                                    <div class="ig-form-field ig-w175">
                                        <p-dropdown formControlName="periodUnit" [options]="periodOptions">
                                        </p-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="ig-field-v-ct ig-margin-l-l">
                                <label  class="ig-nowrap" i18n>Execution Period Precision</label>
                                <div class="ig-form-field ig-w175">
                                    <p-dropdown formControlName="periodPrecision" [options]="periodOptions">
                                    </p-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ig-form-section ig-margin-l-t">
                    <div class=" ig-field-h-ct">
                        <p-checkbox [formControl]="formGroup.controls['archiveDeletedData'] //noinspection InvalidExpressionResultType"
                                    [value]="true"
                                    [binary]="true"
                                    i18n-label label="Move S3 files to Glacier when retention deletes data"></p-checkbox>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
    </form>
</div>
