import {NgModule,Directive,ElementRef,AfterViewInit,OnDestroy,Input} from '@angular/core';
import {DomUtils} from '../domutils/domutils';
import {CommonModule} from '@angular/common';

@Directive({
    selector: '[igButton]'
})
export class ButtonDirective implements AfterViewInit, OnDestroy {
    @Input() tooltip: string;
    @Input() badgeClassName:string;

    private _label: string;
    private _icon: string;
    private _loading: boolean;
    private _badge:string;

    constructor(public el: ElementRef) {}
    
    ngAfterViewInit() {
        DomUtils.addClasses(this.el.nativeElement, this.getStyleClass());

        if ( this.tooltip ) {
            this.el.nativeElement.setAttribute("title", this.tooltip);
            this.el.nativeElement.setAttribute("aria-label", this.tooltip);
        }
    }

    getStyleClass(): string {
        let styleClass = 'ig-button';
        if (!this.label) {
            styleClass = styleClass + ' ig-button-icon-only';
        }
        return styleClass;
    }

    @Input() get label(): string {
        return this._label;
    }

    set label(val: string) {
        this._label = val;

        let labelElement = DomUtils.findSingle(this.el.nativeElement, '.ig-button-label');
        if ( labelElement ) {
            this.el.nativeElement.removeChild( labelElement );
        }

        if ( this._label ) {
            labelElement = document.createElement("span");
            labelElement.className = 'ig-button-label';
            labelElement.appendChild(document.createTextNode(this.label));
            this.el.nativeElement.appendChild(labelElement);
            DomUtils.removeClass(this.el.nativeElement, "ig-button-icon-only");
        } else {
            DomUtils.addClass(this.el.nativeElement, "ig-button-icon-only");
            // throw new Error("Infogix Button Component: caption has not been set");
        }
    }

    @Input()
    public get badge(): string {
        return this._badge;
    }

    public set badge(badgeValue: string) {
        this._badge = badgeValue;
        const badgeClass = this.badgeClassName ?? 'ig-button-badge';

        let badgeElement = DomUtils.findSingle(this.el.nativeElement, "." + badgeClass);
        if (badgeElement) {
            this.el.nativeElement.removeChild(badgeElement);
        }

        if (badgeValue) {
            badgeElement = document.createElement("span");
            badgeElement.className = badgeClass;
            badgeElement.appendChild(document.createTextNode(badgeValue));
            this.el.nativeElement.appendChild(badgeElement);
        }
    }

    @Input() get icon(): string {
        return this._icon;
    }

    set icon(val: string) {
        this._icon = val;

        let iconElement = DomUtils.findSingle(this.el.nativeElement, '.ig-button-icon');
        if ( iconElement ) {
            this.el.nativeElement.removeChild( iconElement );
        }

        if ( this._icon ) {
            let iconElement = document.createElement("span");
            iconElement.setAttribute("aria-hidden", "true");
            iconElement.className = 'ig-button-icon fa ' + this._icon;

            let labelElement = DomUtils.findSingle(this.el.nativeElement, '.ig-button-label');
            if ( labelElement ) {
                this.el.nativeElement.insertBefore(iconElement, labelElement);
            } else {
                this.el.nativeElement.appendChild(iconElement);
            }
        }
    }

    @Input() get loading(): boolean {
        return this._loading;
    }

    set loading(val: boolean) {
        this._loading = val;

        let spinnerElement = DomUtils.findSingle(this.el.nativeElement, '.ig-button-spinner');
        if ( spinnerElement ) {
            this.el.nativeElement.removeChild( spinnerElement );
        }

        if (this._loading) {
            DomUtils.addClass(this.el.nativeElement, "ig-state-loading");
            this.el.nativeElement.setAttribute("disabled", "true");
            
            let spinnerElement = document.createElement("span");
            spinnerElement.setAttribute("aria-hidden", "true");
            spinnerElement.className = 'ig-button-spinner';
            this.el.nativeElement.appendChild(spinnerElement);
        } else {
            DomUtils.removeClass(this.el.nativeElement, "ig-state-loading");
            this.el.nativeElement.setAttribute("disabled", "false");
        }
    }
      
    ngOnDestroy() {
        while(this.el.nativeElement.hasChildNodes()) {
            this.el.nativeElement.removeChild(this.el.nativeElement.lastChild);
        }
    }
}

@NgModule({
    imports: [CommonModule],
    exports: [ButtonDirective],
    declarations: [ButtonDirective]
})
export class ButtonModule { }
