<div class="ig-selected-item-list ig-paginated-table">
    <p-table #table
            [value]="tableData" [dataKey]="dataKey"
             [rowHover]="true"
             [globalFilterFields]="[labelKey]"
             [scrollable]="true"
             [paginator]="true"
             [rows]="pageSize"
             [pageLinks]="pageLinks"
             [showCurrentPageReport]="true" [alwaysShowPaginator]="false"
             [rowsPerPageOptions]="rowsPerPageOptions"
             i18n-currentPageReportTemplate currentPageReportTemplate="{first} to {last} of {totalRecords} items"
             scrollHeight="flex"
             styleClass="p-datatable-striped"
    >
        <ng-template pTemplate="header">
            <tr>
                <th colspan="2" style="display:flex;align-items: center"
                    [pSortableColumn]="labelKey"
                >
                    <span>{{selectedItemsLabel}}
                        <p-sortIcon [field]="labelKey"></p-sortIcon></span>
                    <span class="ig-grow"></span>
                    <span class="warning" *ngIf="tableData?.length > warningRecordLimit"
                          [igTooltip]="getWarningTooltip()" tooltipPosition="bottom">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>Performance Risk</span>
                </th>
            </tr>
            <tr style="border:none">
                <th>
                    <ig-search-field class="ig-flex"
                                     #searchField [placeholder]="searchPlaceholder"
                                     (textChange)="onSearchTextChange($event)"></ig-search-field>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr class="p-selectable-row">
                <td [igTooltip]="(tooltipKey ? rowData[tooltipKey] : null)"
                    tooltipPosition="top">{{rowData[labelKey]}}</td>
                <td class="col-btn">
                    <button type="button" class="ig-icon ig-button" (click)="onRowDeleteClick(rowData)">
                        <i class="fa fa-trash"></i>
                    </button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td colspan="2" i18n>No items selected</td>
            </tr>
        </ng-template>
    </p-table>
    <ig-paginator
            [rows]="table.rows" [first]="table.first" [totalRecords]="table.totalRecords"
            [pageLinkSize]="table.pageLinks" styleClass="p-paginator-bottom"
            (onPageChange)="table.onPageChange($event)" [rowsPerPageOptions]="table.rowsPerPageOptions"
            [currentPageReportTemplate]="table.currentPageReportTemplate"
            [showCurrentPageReport]="table.showCurrentPageReport"
            [showPageLinksOnNewLine]="showPageLinksOnNewLine"
    ></ig-paginator>
</div>