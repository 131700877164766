<div>
    <form [formGroup]="formGroup">
        <table class="controls-grid-header">
            <thead>
            <tr>
                <th>&nbsp;</th>
                <ng-container *ngFor="let layoutRow of layoutGrid; index as rowNum">
                    <ng-container *ngFor="let column of layoutRow; index as colNum">
                        <th *ngIf="rowNum === 0" [style.min-width]="getCellMinWidth()"
                            (click)="onColumnClick($event, colNum)"
                            [className]="isColumnHeaderSelected(colNum) ? 'column-header-selected' : 'column-header-not-selected'">&nbsp;
                        </th>
                    </ng-container>
                </ng-container>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let layoutRow of layoutGrid; index as rowNum">
                <td (click)="onRowClick($event, rowNum)" [style.min-height]="getCellMinHeight()"
                    [className]="isRowHeaderSelected(rowNum) ? 'row-header-selected' : 'row-header-not-selected'">
                    &nbsp;
                </td>
                <ng-container *ngFor="let column of layoutRow; index as colNum">
                    <ng-container *ngIf="column.status === 'CONTROL'">
                        <td [colSpan]="column.field.colSpan" [rowSpan]="column.field.rowSpan"
                            [style.height]="getCellMinHeight()"
                            [style.min-width]="getCellMinWidth()"
                            (click)="onSelectField($event, rowNum, colNum)"
                            [className]="isCellSelected(rowNum, colNum) ? 'cell-selected' : 'cell-not-selected'"
                            pDraggable="controls" (onDragStart)="onDragStart($event, rowNum, colNum)"
                            pDroppable="controls" (onDrop)="onDrop($event, rowNum, colNum)" (onDragEnter)="onDragEnter($event, rowNum, colNum)"
                            dropEffect="relocate" dragEffect="move">
                            <dqp-dynamic-control [controlInfo]="getControlInfo(column)"
                                                 [formGroup]="formGroup"
                                                 (click)="onSelectField($event, rowNum, colNum)">
                            </dqp-dynamic-control>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="column.status === 'FREE'">
                        <td [class]="!isCellSelected(rowNum, colNum) ? 'add-field-cell' : 'add-field-cell-selected'"
                            (click)="onAddField($event, rowNum, colNum)"
                            [style.height]="getCellMinHeight()"
                            [style.min-width]="getCellMinWidth()"
                            pDroppable="controls" (onDrop)="onDrop($event, rowNum, colNum)" (onDragEnter)="onDragEnter($event, rowNum, colNum)"
                            dropEffect="relocate">
                            <a><i class="fa fa-plus-circle"></i><span>Add a field</span></a>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="column.status === 'OBSCURED'">
                        <!--                    obscured -->
                    </ng-container>
                </ng-container>
            </tr>
            </tbody>
        </table>
    </form>
</div>
