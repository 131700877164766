<div class="ig-schedule-calendar" [formGroup]="formGroup">
    <div [style.display]="retentionScheduleMode ? 'none': 'block'" class="ig-field-h-ct ig-margin-l-b">
        <label i18n class="ig-margin-m-r">Enable scheduling</label>
        <ig-switch [formControl]="enableScheduling  //noinspection InvalidExpressionResultType"
        ></ig-switch>
    </div>
    <ng-container *ngIf="enabled" formGroupName="schedule">
        <div class="ig-field-h-ct schedule-header">
            <div class="ig-field-v-ct">
                <label i18n>Execute schedule at</label>
                <ig-time-picker formControlName="executionTime"></ig-time-picker>
            </div>
            <div class="ig-field-v-ct ig-form-field" [style.display]="retentionScheduleMode ? 'none': ''" >
                <label i18n>Time zone</label>
                <div class="ig-form-field">
                    <input pInputText readonly="readonly" [value]="calendarTimeZoneName || orgTimeZoneName"
                           type="text" class="ig-read-only"/>
                </div>
            </div>
            <div class="ig-field-v-ct">
                <label i18n>Frequency</label>
                <p-dropdown [options]="availableRecurrenceFrequencyOptions"
                            formControlName="recurrenceFrequency"
                ></p-dropdown>
            </div>
        </div>
        <div class="recurrence-detail ig-field-h-ct ig-stretch">
            <ng-container [ngSwitch]="recurrenceFrequency.value">
                <dqp-calendar-one-time-freq *ngSwitchCase="'o'" [value]="recurrenceFrequencyDetail"></dqp-calendar-one-time-freq>
                <dqp-calendar-hourly-freq *ngSwitchCase="'h'" [value]="recurrenceFrequencyDetail" [retentionScheduleMode]="retentionScheduleMode"></dqp-calendar-hourly-freq>
                <dqp-calendar-daily-freq *ngSwitchCase="'d'" [value]="recurrenceFrequencyDetail" [retentionScheduleMode]="retentionScheduleMode"></dqp-calendar-daily-freq>
                <dqp-calendar-weekly-freq *ngSwitchCase="'w'" [value]="recurrenceFrequencyDetail" [retentionScheduleMode]="retentionScheduleMode"></dqp-calendar-weekly-freq>
                <dqp-calendar-monthly-freq *ngSwitchCase="'m'" [value]="recurrenceFrequencyDetail" [retentionScheduleMode]="retentionScheduleMode"></dqp-calendar-monthly-freq>
                <dqp-calendar-yearly-freq *ngSwitchCase="'y'" [value]="recurrenceFrequencyDetail" [retentionScheduleMode]="retentionScheduleMode"></dqp-calendar-yearly-freq>
                <dqp-calendar-cron-freq *ngSwitchCase="'c'" [value]="recurrenceFrequencyDetail" [retentionScheduleMode]="retentionScheduleMode"></dqp-calendar-cron-freq>
            </ng-container>
        </div>
    </ng-container>
<!--    <span>{{formGroup.value | json}}</span>-->
</div>