import {BomItem, BomNodeType, Path, StageType, StageTypeInfo} from "../bom-group.model";
import {BomItemNode} from "../tree/bom-item-node";
import {BomItemNodeImpl} from "../tree/bom-item-node-impl";
import {AccessControlList, ACLEntry} from "@cafe/security";

export class PipelineServiceHelper {
    private stageTypeInfos:StageTypeInfo[] = [];


    constructor(stageTypeInfos: StageTypeInfo[]) {
        this.stageTypeInfos = stageTypeInfos;
    }

    /**
     * Convert object from ajax call to {@link BomItemNode}.
     *
     * @param respObj response object from ajax call to convert.
     */
    public toBomItemNode(respObj:any):BomItemNode {
        if (respObj == null) {
            return null;
        }
        const bomItemNode:BomItemNodeImpl = new BomItemNodeImpl();
        bomItemNode.id = respObj.id || "root";
        bomItemNode.displayName = respObj.name;
        bomItemNode.description = respObj.description;
        bomItemNode.type = this.toNodeType(respObj);
        bomItemNode.calendarDef = respObj.calendarDef;
        bomItemNode.childrenLoaded = respObj.children != null;
        bomItemNode.expanded = !!respObj.expanded;
        bomItemNode.accessControlList = PipelineServiceHelper.toAccessControlList(respObj.accessControlList);
        const isRootNode:boolean = bomItemNode.id === "root";
        if (!isRootNode) {
            this.copyPermissions(respObj, bomItemNode);
            if (respObj.leaf) {
                bomItemNode.stageId = respObj.item.stageId;
                bomItemNode.stageType = respObj.item.stageType;
                bomItemNode.executable = this.isExecutable(bomItemNode.stageType)
            }
            bomItemNode.bomItem = this.toBomItem(respObj);
            if (respObj.iconCls) {
                bomItemNode.iconCls = respObj.iconCls;
            }
        }

        // children
        if (respObj.children != null) {
            bomItemNode.children = [];
            for (const nextChildObj of respObj.children) {
                const newChild = this.toBomItemNode(nextChildObj);
                if (newChild) {
                    bomItemNode.addOrUpdateChild(newChild);
                }
            }
        } else {
            bomItemNode.children = null;
        }

        return bomItemNode;
    }

    private isExecutable(stageType:StageType):boolean {
        let executable = false;
        const stageTypeInfo = this.stageTypeInfos.find(next => next.type === stageType);
        if (stageTypeInfo) {
            executable = stageTypeInfo.executable;
        }
        return executable;
    }

    // noinspection JSMethodCanBeStatic
    private copyPermissions(srcObj:any, node:BomItemNode) {
        node.hasAdminPermission = srcObj.hasAdminPermission;
        node.hasWritePermission = srcObj.hasWritePermission;
        node.hasReadDataPermission = srcObj.hasReadDataPermission;
        node.hasChangeDataPermission = srcObj.hasChangeDataPermission;
        node.hasExecutePermission = srcObj.hasExecutePermission;
        node.hasManageSecurityPermission = srcObj.hasManageSecurityPermission;
        node.hasReadAuditDataPermission = srcObj.hasReadAuditDataPermission;
        if (srcObj.stageCreationPermissions) {
            // @ts-ignore
            node.stageCreationPermissions = new Map(Object.entries(srcObj.stageCreationPermissions));
        }
    }


    // noinspection JSMethodCanBeStatic
    private toNodeType(respObj: any):BomNodeType {
        let nodeType: BomNodeType = "PATH";
        if (respObj.pipeline) {
            nodeType = "PIPELINE";
        } else if (respObj.leaf) {
            nodeType = "STAGE";
        }
        return nodeType;
    }

    // noinspection JSMethodCanBeStatic
    private toBomItem(respObj:any):BomItem {
        const bomItemResp:any = respObj.item;
        const lastUpdatedTimestamp = parseInt(bomItemResp.lastUpdateTimestamp);
        let envId = parseInt(bomItemResp.envId);
        const bomItem = {
            id:bomItemResp.itemId,
            dirty:bomItemResp.dirty,
            parentId:bomItemResp.parentId,
            envId: (isNaN(envId) ? null : envId),
            uuid:bomItemResp.uuid,
            tenantId:bomItemResp.tenantId,
            description:bomItemResp.description,
            alternateId:bomItemResp.alternateId,
            displayName:bomItemResp.name,
            pipelineId:bomItemResp.pipelineId,
            lastUpdateTimestamp:(isNaN(lastUpdatedTimestamp) ? undefined: lastUpdatedTimestamp)
        } as BomItem;
        if (bomItemResp.isGroup) {
            // Path
        } else {
            // Stage
            Object.assign(bomItem, {
                stageId:bomItemResp.stageId,
                stageType:bomItemResp.stageType
            });
        }
        return bomItem as BomItem;
    }

    public static toAccessControlList(aclResp:any):AccessControlList {
        let acl:AccessControlList = null;
        if (aclResp) {
            let aclEntries:ACLEntry[] = [];
            let aclEntriesResp = aclResp.aclEntries || [];
            for (let i = 0; i < aclEntriesResp.length; i++) {
                const aclEntryResp = aclEntriesResp[i];
                aclEntries.push({
                    id:aclEntryResp.id,
                    isGroupPrincipal:aclEntryResp.isGroupPrincipal,
                    permissionId:aclEntryResp.permissionId,
                    permissionName:aclEntryResp.permissionName,
                    principalId:aclEntryResp.principalId,
                    principalName:aclEntryResp.principalName,
                    isImpliedPermission:aclEntryResp.isImpliedPermission
                });
            }
            acl = {
                id:aclResp.id,
                inherited:aclResp.isInherited,
                isInherited:aclResp.isInherited,
                inheritedFromObjectId:aclResp.inheritedFromObjectId,
                inheritedFromObjectType:aclResp.inheritedFromObjectType,
                aclEntries:aclEntries
            }
        }
        return acl as AccessControlList;
    }

    public static toPath(pathResp:any):Path {
        if (pathResp) {
            return {
                id:pathResp.id,
                displayName:pathResp.name,
                pipelineId:pathResp.pipelineId,
                parentId:pathResp.parentId,
                isPipeline:pathResp.isPipeline,
                isStage:pathResp.isStage,
                isPath:pathResp.isPath
            } as Path;
        }
    }
}