<div class="ig-form-field ig-w-100pc">
    @if (isReadOnly) {
        <label>{{ getFormControl().value }}</label>
    } @else {
        @if (isInAdderRow()) {
            <!-- In Adder Row-->
            <input type="text" pInputText
                   [formControl]="getFormControl()"
                   [class.adder-first-input]="isFirstColumn()"
                   [placeholder]="getAdderPlaceholder()"
                   [class.ig-required]="hasAdderFieldRequiredError()"
                   (blur)="onAdderTextInputBlur($event)"
                   (keydown)="onAdderTextInputKeyDown($event)"
            />
        } @else {
            <!--In Regular Row-->
            <input type="text" pInputText
                   [formControl]="getFormControl()"
                   [placeholder]="columnConfig.required ? (''|IgConstants).REQUIRED : ''"
                   [class.ig-required]="hasFieldRequiredError()"
            />
        }
        @if (isFieldInvalid()) {
            <span class="ig-feedback">{{ getFieldErrorMsg() }}</span>
        }
    }
</div>