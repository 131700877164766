<div class="dqp-home-tab">
    <div class="north-panel">
        <div class="dqp-content-panel" *ngIf="showNoticeboard">
            <dqp-home-noticeboard [text]="text"></dqp-home-noticeboard>
        </div>
        <div class="dqp-content-panel">
            <dqp-home-favorites></dqp-home-favorites>
        </div>
        <div class="dqp-content-panel">
            <dqp-home-recently-updated></dqp-home-recently-updated>
        </div>
    </div>
    <div class="south-panel">
        <div class="dqp-content-panel home-resources-panel">
            <h1 class="ig-title" i18n>Resources</h1>
            <section>
                <ig-loading [loading]="homeLinksLoading"></ig-loading>
                <ul>
                    <li *ngFor="let nextLink of homeLinks">
                        <i class="fa fa-info-circle"></i>
                        <!--suppress TypeScriptUnresolvedVariable -->
                        <span class="ig-link" (click)="onResourceLinkClick(nextLink.id, nextLink.url)">{{nextLink.label}}</span>
                    </li>
                </ul>
            </section>
        </div>
        <div class="dqp-content-panel home-videos-panel">
            <div *ngIf="showEditResource">
                <span class="ig-link" i18n (click)="onEditResourceButtonClick()"
                      i18n-igTooltip igTooltip="Click to be directed to the Settings page with the ability to edit Resources"
                      tooltipPosition="left">Edit Resource</span>
            </div>
            <dqp-home-tiles-view></dqp-home-tiles-view>
        </div>
    </div>
</div>