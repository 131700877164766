<p-dropdown
        [options]="availableOptions"
        [(ngModel)]="value"
        [placeholder]="placeholder"
        [styleClass]="styleClass"
        [style]="style"
        [disabled]="disabled"
        [appendTo]="appendTo"
        (onChange)="onDropDownChange($event)"
        (onFocus)="focus.emit($event)"
        (onBlur)="onDropDownBlur($event)"
        (onClick)="click.emit($event)"
        (onShow)="show.emit($event)"
        (onHide)="hide.emit($event)"
></p-dropdown>
