<div class="ig-field-v-ct" [formGroup]="formGroup">
    <label class="detail-header" i18n>Daily Frequency</label>
    <div class="ig-form-section">
        <div class="ig-field-h-ct">
            <div class="ig-field-v-ct">
                <label i18n>Number of Days</label>
                <p-spinner formControlName="numberOfDays" min="1" max="365"
                           placeholder="{{(''|IgConstants).REQUIRED}}"
                           [inputStyleClass]="hasFieldRequiredError('numberOfDays') ? 'ig-required': ''">
                </p-spinner>
            </div>
            <div class="ig-field-h-ct no-label-ct ig-margin-m-l">
                <p-checkbox [formControl]="recurEveryWeekday"
                            [value]="true"
                            i18n-label label="Every weekday"></p-checkbox>
            </div>
        </div>
        <dqp-calendar-range-of-recurrence [style.display]="retentionScheduleMode ? 'none': 'block'" [value]="rangeOfRecurrenceFormValues"></dqp-calendar-range-of-recurrence>
    </div>
</div>