<div class="ig-search ig-field ig-grow"
     [ngStyle]="style"
     [class]="styleClass"
     [class.disabled]="disabled"
     [class.ig-focus]="focus"
>
    <input #searchField type="text" [placeholder]="placeholder ? placeholder: ''"
           autocomplete="whatever"
           [value]="value"
           [disabled]="disabled"
           (input)="onTextChange($event, $event.target.value)"
           (focus)="onInputFocus($event)"
           (blur)="onInputBlur($event)"
    >
    <button class="ig-icon ig-button close" i18n-title title="Clear"
            [class]="{'ig-hide':searchField.value == ''}"
            (click)="onClearButtonClick($event)"
    ><i class="fa fa-times"></i>
    </button>
    <button [disabled]="searchButtonDisabled" class="ig-icon ig-button search" i18n-title title="Search">
        <i class="fa fa-search" (click)="onSearchButtonClicked($event)"></i>
    </button>
</div>