<div class="confirm-popup">
    <section class="ig-dialog-content">
        <p *ngIf="!allowHtml">{{message}}</p>
        <p *ngIf="allowHtml" [innerHTML]="message"></p>
    </section>
    <div class="ig-dialog-footer">
        <!-- need this <a> tag to receive focus based on PrimeNG dynamic dialog code. Note that the dialog
        must not configured to be 'closable', otherwise the primeNG close button will receive the focus instead. -->
        <a href="#" (focus)="focusPrimaryButton($event)"></a>
        <ng-container *ngIf="!reverseAction">
            <button *ngIf="showCancelButton" igButton (click)="onCancelClick()" [label]="cancelLabel"></button>
            <button igButton (click)="onNoClick()" [label]="noLabel" class="ig-margin-m-l"></button>
            <span class="spacer"></span>
            <button *ngIf="showYesButton" igButton (click)="onYesClick()" [label]="yesLabel" class="ig-button-primary"></button>
        </ng-container>
        <ng-container *ngIf="reverseAction">
            <button *ngIf="showCancelButton" igButton (click)="onCancelClick()" [label]="cancelLabel"></button>
            <button *ngIf="showYesButton" igButton (click)="onNoClick()" [label]="noLabel" class="ig-margin-m-l"></button>
            <span class="spacer"></span>
            <button igButton (click)="onYesClick()" [label]="yesLabel" class="ig-button-negative"></button>
        </ng-container>
    </div>
</div>