import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation
} from "@angular/core";
import {DashboardPreferenceService} from "@cafe/visualization";
import {StageListItem} from "./stage-item-list.model";
import {BomGroupService} from "../../bom-group.service";
import {PipelineService} from "../../pipeline/pipeline.service";
import {OpenInNewTabPipe} from "@cafe/web-ui-framework";

@Component({
               selector: "dqp-stage-item-list",
               templateUrl: "./stage-item-list.component.html",
               styleUrls: ["./stage-item-list.component.scss"],
               encapsulation: ViewEncapsulation.None,
               changeDetection: ChangeDetectionStrategy.OnPush
           })
export class StageItemListComponent implements OnInit{

    public pathTooltip:string;

    @Input()
    public loading:boolean;

    @Input()
    public deleteButtonTooltip:string;

    @Input()
    public supportOpenInNewTab:boolean = true;


    @Input()
    public items: StageListItem[];

    @Output()
    public itemClick: EventEmitter<{item:StageListItem, originalEvent:MouseEvent}> = new EventEmitter();

    @Output()
    public deleteClick: EventEmitter<StageListItem> = new EventEmitter();

    public constructor(private dashboardPreferenceService: DashboardPreferenceService,
                       private bomGroupService: BomGroupService,
                       private pipelineService:PipelineService,
                       private cdr: ChangeDetectorRef) {
    }

    public ngOnInit(): void {
        this.cdr.markForCheck();
    }

    public removeItem(itemToDelete: StageListItem) {
        const index = this.items.indexOf(itemToDelete);
        if (index > -1) {
            this.items.splice(index, 1);
            this.cdr.markForCheck();
        }
    }

    public onDeleteButtonClick(itemToDelete: StageListItem, event:Event) {
        event.stopPropagation();
        this.deleteClick.emit(itemToDelete);
    }

    public onRowClick(item: StageListItem, event:MouseEvent) {
        this.itemClick.emit({item, originalEvent:event});
    }

    public onPathMouseOver(pathCell: HTMLTableDataCellElement, item: StageListItem) {
        this.pathTooltip = this.getPathTooltipText(pathCell, item);
        this.cdr.markForCheck();
    }

    private getPathTooltipText(pathCell: HTMLTableDataCellElement, item: StageListItem):string {
        let tooltip:string = null;
        const spansNodeList = pathCell.querySelectorAll("span");
        let isOverflowing = false;
        spansNodeList.forEach(nextSpan=>{
            if (nextSpan.clientWidth < nextSpan.scrollWidth) {
                isOverflowing = true;

            }
        })

        if (isOverflowing) {
            tooltip = item.toHtml("dqp-stage-path-tooltip");
        }
        if (this.supportOpenInNewTab) {
            tooltip = new OpenInNewTabPipe().transform(tooltip);
        }
        return tooltip;
    }

    public onPathMouseLeave() {
        this.pathTooltip = null;
        this.cdr.markForCheck();
    }
}
