<div class="ig-field-v-ct" [formGroup]="formGroup">
    <label class="detail-header" i18n>Monthly Frequency</label>
    <div class="ig-form-section">
        <div class="ig-field-h-ct radio-group">
            <p-radioButton name="recurrencePatternType" formControlName="recurrencePatternType" value="DAY_X_OF_EVERY_Y_MONTHS"
                           i18n-label
                           label="Standard calendar day"></p-radioButton>
            <p-radioButton name="recurrencePatternType" formControlName="recurrencePatternType"
                           value="NTH_WEEK_OF_X_DAY_OF_EVERY_Y_MONTHS" i18n-label
                           label="Specific day"></p-radioButton>
        </div>
        <ng-container [ngSwitch]="recurrencePatternType.value">
            <div *ngSwitchCase="'DAY_X_OF_EVERY_Y_MONTHS'" class="ig-field-h-ct ig-margin-l-b label-ct">
                <label i18n>Select</label>
                <p-dropdown formControlName="dayOfMonth"
                            placeholder="{{(''|IgConstants).REQUIRED}}"
                            [options]="availableDaysOfMonthOptions"
                            [styleClass]="(hasFieldRequiredError('dayOfMonth') ? ' ig-required' : '')">
                </p-dropdown>
                <label i18n>of every</label>
                <p-dropdown formControlName="recurEveryMonths"
                            placeholder="{{(''|IgConstants).REQUIRED}}"
                            [options]="availableMonthsOptions"
                            [styleClass]="(hasFieldRequiredError('recurEveryMonths') ? ' ig-required' : '')">
                </p-dropdown>
                <label>months</label>
            </div>
            <div *ngSwitchCase="'NTH_WEEK_OF_X_DAY_OF_EVERY_Y_MONTHS'" class="ig-field-h-ct ig-margin-l-b label-ct">
                <label i18n>Select</label>
                <p-dropdown formControlName="weekOfMonth"
                            placeholder="{{(''|IgConstants).REQUIRED}}"
                            [options]="availableWeeksOfMonthOptions"
                            [styleClass]="(hasFieldRequiredError('weekOfMonth') ? ' ig-required' : '')">
                </p-dropdown>

                <p-dropdown formControlName="dayOfWeek"
                            placeholder="{{(''|IgConstants).REQUIRED}}"
                            [options]="availableDaysOfWeekOptions"
                            [styleClass]="(hasFieldRequiredError('dayOfWeek') ? ' ig-required' : '')">
                </p-dropdown>

                <label i18n>of every</label>
                <p-dropdown formControlName="recurEveryMonths"
                            placeholder="{{(''|IgConstants).REQUIRED}}"
                            [options]="availableMonthsOptions"
                            [styleClass]="(hasFieldRequiredError('recurEveryMonths') ? ' ig-required' : '')">
                </p-dropdown>
                <label>months</label>
            </div>
        </ng-container>
        <dqp-calendar-range-of-recurrence [style.display]="retentionScheduleMode ? 'none': 'block'" [value]="rangeOfRecurrenceFormValues"></dqp-calendar-range-of-recurrence>
    </div>
</div>