<li
        class="p-multiselect-item"
        (click)="onOptionClick($event)"
        (keydown)="onOptionKeydown($event)"
        [attr.aria-label]="label"
        [attr.tabindex]="disabled ? null : '0'"
        [ngStyle]="{ height: itemSize + 'px' }"
        [ngClass]="{ 'p-highlight': selected, 'p-disabled': disabled }"
        pRipple
>
    <div class="p-checkbox p-component">
        <div class="p-checkbox-box" [ngClass]="{ 'p-highlight': selected }">
            <ng-container *ngIf="selected">
                <CheckIcon *ngIf="!checkIconTemplate" [styleClass]="'p-checkbox-icon'"/>
                <span *ngIf="checkIconTemplate" class="p-checkbox-icon">
                    <ng-template *ngTemplateOutlet="checkIconTemplate"></ng-template>
                </span>
            </ng-container>
        </div>
    </div>
    <span *ngIf="!template">{{ label }}</span>
    <ng-container *ngTemplateOutlet="template; context: { $implicit: option }"></ng-container>
</li>