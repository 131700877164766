<div class="dqp-watcher">
    <section class="ig-dialog-content">
        <form [formGroup]="formGroup">
            <div class="ig-form-section">
                <div class=" ig-field-v-ct ig-stretch">
                    <label i18n>Users/Groups</label>
                    <div class="ig-form-field ig-grow">
                        <p-chips formControlName="watchers" field="principalName" (onRemove)="onWatcherRemove($event)"
                                 i18n-placeholder placeholder="optional"
                        ></p-chips>
                    </div>
                </div>
            </div>
        </form>
    </section>
    <div class="ig-dialog-footer">
        <button igButton (click)="onCloseClick()" i18n-label label="Close"></button>
        <span class="spacer"></span>
        <button igButton (click)="onAddWatchersClick()" i18n-label label="Add Watchers"
                class="ig-button-primary"></button>
<!--                {{formGroup.value | json}}-->
    </div>
</div>