<div class="ig-item-selector">
    <ig-paginated-list [dataLoader]="dataLoader" [dataKey]="dataKey" [labelKey]="labelKey"
                       [tooltipKey]="tooltipKey"
                       [pageSize]="pageSize"
                       [selectedItemsSearchPlaceholder]="selectedItemsSearchPlaceholder"
                       [availableItemsLabel]="availableItemsLabel"
                       [pageLinks]="pageLinks"
                       [warningRecordLimit]="warningRecordLimit"
                       [(selectedData)]="selectedItems"
                       [rowsPerPageOptions]="rowsPerPageOptions"
                       [showPageLinksOnNewLine]="showPageLinksOnNewLine"
    >
    </ig-paginated-list>
    <ig-selected-item-list [(tableData)]="selectedItems"
                           [searchPlaceholder]="availableItemsSearchPlaceholder"
                           [selectedItemsLabel]="selectedItemsLabel"
                           [warningRecordLimit]="warningRecordLimit"
                           [dataKey]="dataKey" [labelKey]="labelKey"
                           [tooltipKey]="tooltipKey"
                           [pageLinks]="pageLinks"
                           [rowsPerPageOptions]="rowsPerPageOptions"
                           [showPageLinksOnNewLine]="showPageLinksOnNewLine"
    ></ig-selected-item-list>

</div>
