<div>
    <section class="ig-dialog-content">
        <div class="ig-advisory ig-margin-l-b" i18n>{{this.tile.description}}</div>
        <div *ngFor="let nextLink of this.tile.links" class="ig-margin-l-b" >
            <label class="ig-bold">{{nextLink.label}}</label>
            <div class="ig-link" (click)="onLinkClick(nextLink)">{{nextLink.url}}</div>
        </div>
    </section>

    <div class="ig-dialog-footer">
        <button igButton (click)="onCancelClick()" i18n-label label="Cancel"></button>

    </div>
</div>
