<div class="ig-field-v-ct" [formGroup]="formGroup">
    <label class="detail-header" i18n>Weekly Frequency</label>
    <div class="ig-form-section">
        <div class="ig-field-h-ct">
            <div class="ig-field-v-ct">
                <label i18n>Recur every</label>
                <p-spinner formControlName="recurEveryWeek" min="1" max="52"
                           placeholder="{{(''|IgConstants).REQUIRED}}"
                           [inputStyleClass]="hasFieldRequiredError('recurEveryWeek') ? 'ig-required': ''">
                </p-spinner>
            </div>
        </div>
        <div class="ig-field-h-ct">
            <div class="ig-field-v-ct">
                <label i18n>Select day</label>
                <div class="checkbox-group">
                    <p-checkbox [formControl]="recurDays" [value]="'sunday'" i18n-label label="Sunday"
                                [styleClass]="getCheckboxGroupStyleClass('recurDays')"></p-checkbox>
                    <p-checkbox [formControl]="recurDays" [value]="'monday'" i18n-label label="Monday"
                                [styleClass]="getCheckboxGroupStyleClass('recurDays')"></p-checkbox>
                    <p-checkbox [formControl]="recurDays" [value]="'tuesday'" i18n-label label="Tuesday"
                                [styleClass]="getCheckboxGroupStyleClass('recurDays')"></p-checkbox>
                    <p-checkbox [formControl]="recurDays" [value]="'wednesday'" i18n-label label="Wednesday"
                                [styleClass]="getCheckboxGroupStyleClass('recurDays')"></p-checkbox>
                    <p-checkbox [formControl]="recurDays" [value]="'thursday'" i18n-label label="Thursday"
                                [styleClass]="getCheckboxGroupStyleClass('recurDays')"></p-checkbox>
                    <p-checkbox [formControl]="recurDays" [value]="'friday'" i18n-label label="Friday"
                                [styleClass]="getCheckboxGroupStyleClass('recurDays')"></p-checkbox>
                    <p-checkbox [formControl]="recurDays" [value]="'saturday'" i18n-label label="Saturday"
                                [styleClass]="getCheckboxGroupStyleClass('recurDays')"></p-checkbox>
                </div>
            </div>
        </div>
        <dqp-calendar-range-of-recurrence [style.display]="retentionScheduleMode ? 'none': 'block'" [value]="rangeOfRecurrenceFormValues"></dqp-calendar-range-of-recurrence>
    </div>
</div>