<!------------------------------------------------------------------------------
       Add/Edit property popup
 ------------------------------------------------------------------------------->
<div>
    <section class="ig-dialog-content">
        <form [formGroup]="formGroup">
            <div class="ig-form-section">
                <div class="ig-field-h-ct ig-margin-m-b">
                    <label i18n class="ig-margin-m-r">Enable encryption</label>
                    <ig-switch [formControl]="isEncrypted  //noinspection InvalidExpressionResultType"
                    ></ig-switch>
                </div>
                <div class=" ig-field-v-ct ig-stretch">
                    <label i18n>Property Name</label>
                    <div class="ig-form-field">
                        <input igFormControl pInputText formControlName="propertyName" maxlength="250" type="text"/>
                        <span *ifErrorForField="'propertyName';errorKey:'duplicateName'" class="ig-feedback" i18n>Name already exists</span>
                    </div>
                </div>
                <div class=" ig-field-v-ct ig-stretch ig-margin-m-b">
                    <label i18n>Property Value</label>
                    <dqp-encrypted-text-field [encryptionEnabled]="isEncrypted.value" formControlName="propertyValue"
                                              (confirmChangePopupDismiss)="onConfirmChangePopupDismiss($event)"
                                              [styleClass]="{'ig-w-100pc':true}"></dqp-encrypted-text-field>
                </div>
            </div>
        </form>
    </section>
    <div class="ig-dialog-footer">
        <button igButton (click)="onCancelClick()" i18n-label label="Cancel"></button>
        <span class="spacer"></span>
        <button igButton (click)="onSaveButtonClick()" i18n-label label="Apply Changes"
                [disabled]="formGroup?.invalid || !formGroup.dirty"
                class="ig-button-primary"></button>
<!--        {{formGroup.value | json}}-->
    </div>
</div>




