import {Inject, NgModule, Optional} from "@angular/core";
import {ExtraOptions, ROUTER_CONFIGURATION, RouterModule, Routes} from "@angular/router";
import {APP_BASE_HREF, LocationStrategy, PathLocationStrategy, PlatformLocation} from "@angular/common";

import {HashLocationStrategyExt} from "@cafe/web-ui-framework";


const routes: Routes = [
    {
        path: "caf/devenv",
        loadChildren: () => import("@cafe/dev-env").then(m => m.DevEnvAdminModule)
    },
    {
        path: "caf/groups",
        loadChildren: () => import("@cafe/security").then(m => m.GroupModule)
    },
    {
        path: "caf/orgpreferences",
        loadChildren: () => import("@cafe/business-account-management").then(m => m.BusinessAccountManagementModule)
    },
    {
        path: "caf/schedules",
        loadChildren: () => import("@cafe/business-account-management").then(m => m.SchedulesModule)
    },
    {
        path: "pipeline",
        loadChildren: () => import("@cafe/bom-group").then(m => m.BomGroupModule)
    },
    {
        path: "caf/rlgenerator",
        loadChildren: () => import("@cafe/bom-group").then(m => m.RuleLibraryGeneratorModule)
    },
    {
        path: "caf/viewdatastore/:stageId",
        loadChildren: () => import("@cafe/data-store").then(m => m.ViewDataStoreModule)
    },
    {
           path: "caf/editdatastore/:stageId",
           loadChildren: () => import("@cafe/data-store").then(m => m.EditDataStoreModule)
       },
    {
        path: "caf/editdatastore/:stageId/:storeType/:parentPathId",
        loadChildren: () => import("@cafe/data-store").then(m => m.EditDataStoreModule)
    },
    {
        path: "caf/newdatastore/:parentPathId",
        loadChildren: () => import("@cafe/data-store").then(m => m.NewDataStoreModule)
    }
];

export function provideLocationStrategy(
    platformLocationStrategy: PlatformLocation, baseHref: string, options: ExtraOptions = {}) {
    return options.useHash ? new HashLocationStrategyExt(platformLocationStrategy, baseHref) :
        new PathLocationStrategy(platformLocationStrategy, baseHref);
}

const modulesWithProviders = RouterModule.forRoot(routes,
    {
        useHash: true,
        paramsInheritanceStrategy: "always",
        // enableTracing: true, // <-- debugging purposes only
    });

@NgModule({
    imports: [modulesWithProviders],
    providers: [
        {
            provide: LocationStrategy,
            useFactory: provideLocationStrategy,
            deps: [
                PlatformLocation, [new Inject(APP_BASE_HREF), new Optional()], ROUTER_CONFIGURATION
            ]
        },
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
