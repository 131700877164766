<div class="ig-form-field ig-w-100pc">
    <label *ngIf="isReadOnly;else notReadOnly">{{getFormControl().value | yesNo}}</label>
    <ng-template #notReadOnly>
        <ng-container *ngIf="!isInAdderRow();else inAdderRow">
            <!--In Regular Row-->
            <p-checkbox [formControl]="getFormControl()"
                        [value]="true"></p-checkbox>
        </ng-container>

        <ng-template #inAdderRow>
            <!-- In Adder Row-->
            <p-checkbox #adderRowCheckbox [formControl]="getFormControl()"
                        [value]="true"
            ></p-checkbox>
        </ng-template>
    </ng-template>

</div>