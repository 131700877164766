<div class="site">
  <header *ngIf="showTopNav && !isPortalMode">
    <dqp-app-top-nav></dqp-app-top-nav>
      <dqp-app-title-bar></dqp-app-title-bar>
  </header>
  <main>
    <div id="main-container" [style.display]="isShowingExtJsView ? 'none': 'block'">
      <router-outlet (activate)="onRouterOutletActivate($event)" (deactivate)="onRouterOutletDeactivate($event)"></router-outlet>
    </div>
    <div id="extjs-container" [style.display]="isShowingExtJsView ? 'block': 'none'"></div>
  </main>
</div>

<!-- application mask -->
<dqp-app-mask></dqp-app-mask>

<!-- toast -->
<p-toast></p-toast>
<p-toast position="top-center" key="top-center"></p-toast>
<p-toast position="bottom-center" key="bottom-center"></p-toast>
<p-toast position="bottom-right" key="bottom-right"></p-toast>
