import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {AjaxService} from "../ajax/ajax.service";
import {Injectable} from "@angular/core";

@Injectable({providedIn:"root"})
export class TimeZoneService {
    private timeZoneInfosCache: TimeZoneInfo[] = null;

    constructor(private ajaxService: AjaxService) {
    }

    public listTimeZones(): Observable<TimeZoneInfo[]> {
        if (this.timeZoneInfosCache == null) {
            const graphqlQuery = `query {
                                    systemInfo {
                                      timeZones {
                                        id
                                        displayName
                                      }
                                    }
                                  }`;
            return this.ajaxService.query(graphqlQuery)
                .pipe(
                    map((response) => {
                        const timeZones = response.data.systemInfo.timeZones;
                            this.timeZoneInfosCache = timeZones;
                            return timeZones
                        }
                    )
                );
        } else {
            return of(this.timeZoneInfosCache);
        }
    }


}

export class TimeZoneInfo {
    id: string;
    displayName: string;
    offset: number;
    observesDaylightTime: boolean;
}
