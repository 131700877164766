<div>
    <form [formGroup]="formGroup">
        <div class="ig-field-v-ct">
            <label i18n>Root Name</label>
            <div class="ig-form-field ig-w-c12">
                <input pInputText formControlName="rootName" type="text" maxlength="50" placeholder="{{(''|IgConstants).REQUIRED}}"/>
                <div [style.visible]="formGroup?.dirty && formGroup?.invalid" style="min-height: 30px">
                    <span class="ig-feedback" *ngIf="rootName.errors?.duplicateRootName" i18n>Name already exists</span>
                </div>
            </div>
        </div>
    </form>
    <div class="ig-dialog-footer">
        <button igButton (click)="onCancelClick()" i18n-label label="Cancel"></button>
        <span class="spacer"></span>
        <button igButton (click)="onCreateClick()" i18n-label label="Create"
                [disabled]="formGroup?.invalid"
                class="ig-button-primary"></button>
    </div>
</div>
