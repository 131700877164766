<!--
  ~ Copyrdqpht (c) 2020 Infogix, Inc (formerly known as Unitech Systems, Inc).
  ~ 1240 E. Diehl Rd, 4th Floor, Naperville, IL 60563, U.S.A.
  ~ All rdqphts reserved.
  ~
  ~ This software is the confidential and proprietary information of
  ~ Infogix, Inc. ("Confidential Information").  You shall not
  ~ disclose such Confidential Information and shall use it only in
  ~ accordance with the terms of the license agreement you entered into
  ~ with Infogix.
  -->

<div class="dqp-view">
    <div class="ig-margin-s-t ig-margin-s-l edit-width">
        <p-accordion id="data-store-accordion-view-mode" [multiple]="true">
            <p-accordionTab [selected]="true">
                <p-header>
                    <span class="p-accordion-header-text" i18n>General</span>
                </p-header>
                <div class="dqp-view-property-v-ct">
                    <label class="name" i18n>Data Store Name</label>
                    <label class="value">{{getViewPropertyValue('displayName')}}</label>
                </div>
                <div class="dqp-view-property-v-ct">
                    <label class="name" i18n>Description</label>
                    <label class="value">{{getViewPropertyValue('description')}}</label>
                </div>
                <div class="dqp-view-property-v-ct">
                    <label class="name" i18n>Store Type</label>
                    <label class="value" il8n>{{getStoreTypeDisplayValue()}}</label>
                </div>
                <div class="dqp-view-property-v-ct">
                    <label class="name" i18n>Store Repository Type</label>
                    <label class="value">{{getStoreRepositoryTypeDisplayValue()}}</label>
                </div>
                <div *ngIf="showLayoutOptions()" class="dqp-view-property-v-ct">
                    <label class="name" i18n>Layout Type</label>
                    <label class="value">{{getLayoutTypeDisplayValue()}}</label>
                </div>
                <div class="dqp-view-property-v-ct">
                    <label class="name" i18n>Create new definition version on each save</label>
                    <label class="value">{{getViewPropertyValue('createNewDefVersion', true)}}</label>
                </div>
                <div class="dqp-view-property-v-ct">
                    <label class="name" i18n>Log Activity</label>
                    <label class="value">{{getViewPropertyValue('logActivity', true)}}</label>
                </div>
                <div class="dqp-view-property-v-ct">
                    <label class="name" i18n>Allow Ad Hoc Queries</label>
                    <label class="value">{{getViewPropertyValue('allowAdHocQueries', true)}}</label>
                </div>
                <div class="dqp-view-property-v-ct">
                    <label class="name" i18n>Cache Query Results</label>
                    <label class="value">{{getViewPropertyValue('cacheQueryResults', true)}}</label>
                </div>
            </p-accordionTab>

            <div *ngIf="formGroup.get('storeRepositoryType').value == 'S_3' && isAWSCloud">
                <dqp-s3-channel-view
                        [selectedDataStoreData]="selectedDataStoreDetail"></dqp-s3-channel-view>
            </div>
            <div *ngIf="storeRepositoryTypeValue == 'FILE_SYSTEM' && isEnterpriseMode">
                <dqp-file-system-channel-view
                        [selectedDataStoreData]="selectedDataStoreDetail"></dqp-file-system-channel-view>
            </div>
            <div *ngIf="formGroup.get('storeRepositoryType').value == 'KAFKA'">
                <dqp-kafka-channel-view
                        [selectedDataStoreData]="selectedDataStoreDetail"
                        [selectedLayoutType]="layoutTypeValue"></dqp-kafka-channel-view>
            </div>
            <div *ngIf="formGroup.get('storeRepositoryType').value == 'HDFS'">
                <dqp-hdfs-channel-view
                        [selectedDataStoreData]="selectedDataStoreDetail"></dqp-hdfs-channel-view>
            </div>
            <div *ngIf="isStoreTypeExternal && formGroup.get('storeRepositoryType').value == 'SQL'">
                <dqp-database-channel-view
                        [selectedDataStoreData]="selectedDataStoreDetail"></dqp-database-channel-view>
            </div>
            <div *ngIf="showLayout && formGroup.get('storeRepositoryType').value != 'KAFKA'">
                <dqp-file-layout-view [selectedDataStoreData]="selectedDataStoreDetail"></dqp-file-layout-view>
            </div>
        </p-accordion>
    </div>
</div>
