import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ApplicationRef, DoBootstrap, Injector, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {
    AppMaskModule,
    AppService,
    AuthenticationService,
    RouterUrlHandlingStrategy,
    RouterUrlSerializer,
    RouterUtil,
    WebUiFrameworkModule
} from "@cafe/web-ui-framework";
import {ProfileManagementModule} from "@cafe/profile-management";
import {VisualizationModule} from "@cafe/visualization";
import {AppRoutingModule} from "./app-routing.module";
import {UrlHandlingStrategy, UrlSerializer} from "@angular/router";
import {HttpClientModule} from "@angular/common/http";
import {MessageService} from "primeng/api";
import {
    AlertModule,
    ButtonModule,
    TitleBarModule,
    ToggleButtonModule,
    TooltipModule, UtilModule,
    LoadingModule
} from "@infogix/angular-ui-framework";
import {TopNavComponent} from "./nav/topnav.component";
import {MenubarExtModule} from "./nav/menubar.component";
import {DialogService, DynamicDialogModule} from "primeng/dynamicdialog";
import {DropdownModule} from "primeng/dropdown";
import {CookieService} from "ngx-cookie-service";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {MyAccountComponent} from "./nav/myaccount.component";
import {MenuModule} from "primeng/menu";
import {AppTitleBarComponent} from "./titlebar/app-title-bar.component";
import {DialogModule} from "primeng/dialog";
import {MenubarModule} from "primeng/menubar";
import {TabViewModule} from "primeng/tabview";
import {TableModule} from "primeng/table";
import {HomeComponent} from "./home/home.component";
import {CommonModule} from "@angular/common";
import {BomGroupModule} from "@cafe/bom-group";
import {BusinessAccountManagementModule, SchedulesModule} from "@cafe/business-account-management";
import {createCustomElement} from "@angular/elements";
import {HomeFavoritesComponent} from "./home/favorites/home-favorites.component";
import {HomeVideosComponent} from "./home/video/home-videos.component";
import {HomeVideoItemComponent} from "./home/video/home-video-item.component";
import {HomeRecentlyUpdatedComponent} from "./home/recently-updated/home-recently-updated.component";
import {HomeNoticeboardComponent} from "./home/noticeboard/home-noticeboard.component";
import {HomeNoticeboardEditorComponent} from "./home/noticeboard/home-noticeboard-editor.component";
import {EditorModule} from "primeng/editor";
import {ToastModule} from "primeng/toast";

// legacy route URLS that need to be supported
const ADDITIONAL_ROUTE_URLS = ['pipeline'];

@NgModule({
              declarations: [
                  AppComponent,
                  TopNavComponent,
                  MyAccountComponent,
                  AppTitleBarComponent,
                  HomeComponent,
                  HomeFavoritesComponent,
                  HomeRecentlyUpdatedComponent,
                  HomeVideosComponent,
                  HomeVideoItemComponent,
                  HomeNoticeboardComponent,
                  HomeNoticeboardEditorComponent
              ],
              imports: [
                  BrowserModule,
                  HttpClientModule,
                  AppRoutingModule,
                  BrowserAnimationsModule,
                  WebUiFrameworkModule,
                  ProfileManagementModule,
                  VisualizationModule,
                  AlertModule,
                  MenubarExtModule,
                  DropdownModule,
                  OverlayPanelModule,
                  MenuModule,
                  TitleBarModule,
                  DialogModule,
                  ButtonModule,
                  TableModule,
                  AppMaskModule,
                  MenubarModule,
                  DynamicDialogModule,
                  CommonModule,
                  TabViewModule,
                  BomGroupModule,
                  TooltipModule,
                  ToggleButtonModule,
                  EditorModule,
                  UtilModule,
                  LoadingModule,
                  BusinessAccountManagementModule,
                  ToastModule,
                  SchedulesModule
              ],
    providers: [
        MessageService,
        DialogService,
        CookieService,
        {provide: UrlHandlingStrategy, useClass: RouterUrlHandlingStrategy, deps:[RouterUtil]},
        {provide: UrlSerializer, useClass: RouterUrlSerializer, deps:[RouterUtil]},
        {provide: RouterUtil.SUPPORTED_URL_TOKEN, useValue: ADDITIONAL_ROUTE_URLS},
    ],
    bootstrap: [AppComponent]
})
export class AppModule implements DoBootstrap {
    // services may not used, but need to be instantiated for DI
    // noinspection JSUnusedLocalSymbols
    constructor(private appService: AppService,
                private authenticationService: AuthenticationService,
                private injector:Injector) {
        const webComponent = createCustomElement(HomeComponent, {injector});
        customElements.define("dqp-home", webComponent);
    }

    public ngDoBootstrap(appRef: ApplicationRef): void {
    }

}
