declare const Igx: any;

export class HomeLink {
    public static ABOUT_LINK_ID:string = "dqpAbout360";

    private static DEFAULT_LINKS: HomeLink[] = null;

    public static getDefaultLinks(): HomeLink[] {
        if (HomeLink.DEFAULT_LINKS == null) {
            HomeLink.DEFAULT_LINKS = [
                new HomeLink("dqpWhatsNew", $localize`What's New`,
                    "https://help.precisely.com/access/sources/D3D_Data360_DQ/topic?topicID=D3D-0002",
                    false),
                new HomeLink("dqpUserGuide", $localize`User Guide`,
                             HomeLink.getHelpDocUrl("cshelp.html?contextID=D3D-1002"),
                             false),
                new HomeLink("dqpAdminGuide", $localize`Administrative Guide`,
                             HomeLink.getHelpDocUrl("cshelp.html?contextID=D3D-1003"),
                             false),
                new HomeLink("dqpCommunity", $localize`Community`,
                             "https://community.precisely.com/communities/community-home?communitykey=e856d8c3-cb9c-4c6a-89da-874445de6bef",
                             false),
                new HomeLink(HomeLink.ABOUT_LINK_ID,
                             $localize`About Data 360 DQ+`, null, false)
            ]
        }
        return HomeLink.DEFAULT_LINKS;
    }

    public static findDefaultLink(linkId:string):HomeLink {
        return HomeLink.getDefaultLinks().find(next=> next.id === linkId);
    }

    /**
     * Checks if the given link IDs are the same as the default links, taking order into consideration.
     *
     * @param linkIds
     */
    public static isAllDefaultLinks(linkIds:string[]):boolean {
        let allDefaultLinks = true;
        const defaultLinks = HomeLink.getDefaultLinks();
        linkIds = linkIds ?? [];
        if (linkIds.length > 0 && linkIds.length === defaultLinks.length) {
            for (let i = 0; i < linkIds.length; i++) {
                const nextLinkId:string = linkIds[i];
                const nextDefaultLinkId:string = defaultLinks[i].id;
                if (nextLinkId !== nextDefaultLinkId) {
                    allDefaultLinks = false;
                    break;
                }
            }
        } else {
            allDefaultLinks = false;
        }
        return allDefaultLinks;
    }

    public id:string;
    public label:string;
    public url:string;
    public editable:boolean;

    constructor(id: string, label: string, url: string, editable: boolean = true) {
        this.id = id;
        this.label = label;
        this.url = url;
        this.editable = editable;
    }

    private static getHelpDocUrl(url: string): string {
        return Igx.common.webuiframework.guidedtour.TourDirector.getPDFUrl(url);
    }
}