<div>
    <section class="ig-dialog-content download">
        <form [formGroup]="formGroup">
            <div i18n>Choose dates and times to specify the period to download servers logs for:</div>
            <div class="ig-field-v-ct ig-padding-m-t">
                <div class="ig-field-h-ct">
                    <div class="ig-field-v-ct">
                        <label i18n>From</label>
                        <div class="ig-form-field">
                            <p-calendar formControlName="startDate" [showIcon]="true"
                                        inputStyleClass="ig-required"
                                        appendTo="body"
                                        [maxDate]="today"
                                        placeholder="{{(''|IgConstants).REQUIRED}}"></p-calendar>
                        </div>
                    </div>
                    <div class="ig-field-v-ct ig-padding-s-l">
                        <label i18n>&nbsp;</label>
                        <ig-time-picker formControlName="startTime" appendTo="body"></ig-time-picker>
                    </div>
                </div>
                <ng-container *ngIf="isFieldInvalid('startDate') || isFieldInvalid('startTime')">
                                      <span *ngIf="startDate.errors?.invalidStartDate || startTime.errors?.invalidStartDate"
                                            class="ig-feedback" i18n>Start date & time must be before end date & time</span>
                </ng-container>
            </div>
            <div class="ig-field-v-ct ig-padding-m-t">
                <div class="ig-field-h-ct">
                    <div class="ig-field-v-ct">
                        <label i18n>To</label>
                        <div class="ig-form-field">
                            <p-calendar formControlName="endDate" [showIcon]="true"
                                        inputStyleClass="ig-required"
                                        appendTo="body"
                                        [maxDate]="today"
                                        placeholder="{{(''|IgConstants).REQUIRED}}"></p-calendar>
                        </div>
                    </div>
                    <div class="ig-field-v-ct ig-padding-s-l">
                        <label i18n>&nbsp;</label>
                        <ig-time-picker formControlName="endTime" appendTo="body"></ig-time-picker>
                    </div>
                </div>
                <ng-container *ngIf="isFieldInvalid('endDate') || isFieldInvalid('endTime')">
                                        <span *ngIf="endDate.errors?.invalidEndDate || endTime.errors?.invalidEndDate"
                                              class="ig-feedback" i18n>End date & time must be after start date & time</span>
                </ng-container>
            </div>
        </form>
    </section>
    <div class="ig-padding-m-b" style="min-height: 35px;">{{ phase }}</div>
    <p-progressBar [value]="progressPercent" [showValue]="false" [style]="{'height': '6px'}"></p-progressBar>
    <div class="ig-dialog-footer">
        <button igButton (click)="onCancelClick()" i18n-label label="Close" [disabled]="downloadStarted"></button>
        <span class="spacer"></span>
        <button igButton (click)="startDownload()" i18n-label label="Download"
                [disabled]="formGroup?.invalid || downloadStarted"
                class="ig-button-primary"></button>
    </div>
</div>
