<p-tieredMenu #tileKebabMenu [popup]="true" [model]="tileKebabMenuItems" appendTo="body"></p-tieredMenu>
<div class="dqp-home-tiles">
    <div class="square-ct" *ngFor="let item of tileItems;let index=index">
        <div class="content">
            <dqp-home-tile [tile]="item"
                           (deleteClick)="onTileDeleteButtonClick(index, $event)"
                           (tileUpdated)="onTileUpdated(index, $event)"
                           (kebabClick)="onTileKebabButtonClick(index, $event)"
            >
            </dqp-home-tile>
        </div>
    </div>
</div>

