<div>
    <section class="p-grid dqp-view">
        <div class="dqp-toolbar top-toolbar">
            <button igButton i18n-label label="Preview" (click)="onPreview($event)" class="ig-button-light-gray">
            </button>
            <button igButton i18n-label label="Edit Script" (click)="onEditScript($event)" class="ig-button-light-gray">
            </button>
            <span class="toolbar-buttons-separator"> </span>
            <button igButton i18n-label label="Clear" (click)="onClear($event)" class="ig-button-light-gray" [disabled]="clearDisabled">
            </button>
            <button igButton i18n-label label="Delete" (click)="onDelete($event)" class="ig-button-light-gray" [disabled]="deleteDisabled">
            </button>
        </div>
        <div class="south-panel p-grid p-col-12">
            <!--
             ----------------------------------------------
             Left panel
             ----------------------------------------------
             -->
            <div class="left-panel p-col-9" (click)="onClickOutside($event)">
                <div class="p-grid p-ai-stretch vertical-container">
                    <div class="p-col">
                        <div class="box box-stretched ig-padding-s">
                            <div class="ig-horizontal-container">
                                <dqp-controls-grid [screenDefinition]="screenDefinition"
                                                   [recordFields]="recordFields"
                                                   (defChanged)="onDefChangedByGrid($event)"
                                                   (fieldSelected)="onFieldSelected($event)"
                                                   (numSelectedFields)="onNumSelectedFields($event)"
                                                   #controlsGrid>
                                </dqp-controls-grid>
                                <div class="ig-margin-s-l">
                                    <button igButton icon="fa-plus" i18n-label label="" (click)="onAddColumn($event)"
                                            class="ig-button-light-gray ig-button-small-plus" [disabled]="!isAddColumnEnabled()">
                                    </button>
                                </div>
                            </div>
                            <div class="ig-margin-s-t">
                                <button igButton icon="fa-plus" i18n-label label="" (click)="onAddRow($event)"
                                        class="ig-button-light-gray ig-button-small-plus" [disabled]="!isAddRowEnabled()">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--
            ----------------------------------------------
            right panel
            ----------------------------------------------
            -->
            <div class="right-panel p-col-3">
                <div class="p-grid p-ai-stretch vertical-container">
                    <div class="p-col">
                        <div class="box box-stretched">
                            <dqp-form-screen-properties-panel #propertiesPanel
                                                              [screenDefinition]="screenDefinition"
                                                              [recordFields]="recordFields"
                                                              [editedField]="selectedField$ | async"
                                                              [numSelectedFields]="numSelectedFields$ | async"
                                                              (defChanged)="onDefChangedByProps($event)"
                            >
                            </dqp-form-screen-properties-panel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="dqp-toolbar">
        <button igButton i18n-label label="Close"
                (click)="onClose($event)"
                class="ig-button-light-gray">
        </button>
        <button igButton i18n-label label="Discard Changes"
                (click)="onDiscardChanges($event)"
                [disabled]="!isDataChanged"
                class="ig-button-light-gray">
        </button>
        <span class="ig-grow"></span>
        <button igButton i18n-label label="Accept Changes"
                (click)="onAcceptChanges($event)"
                [disabled]="isAcceptDisabled()"
                class="ig-button-primary">
        </button>
    </div>
</div>
