<div>
    <div class="p-col-12 dqp-toolbar ig-row-justify-end" style="margin:8px 0">
<!--        <ig-search-field class="ig-grow" (textChange)="filterTable($event)"-->
<!--                         i18n-placeholder placeholder="Search properties..."></ig-search-field>-->
        <button igButton icon="fa-plus" i18n-label label="New" (click)="onNewClick($event)"></button>
    </div>
    <!------------------------------------------------------------------------------
       Table
    ------------------------------------------------------------------------------->
    <!-- Popup menu for row icon -->
    <p-menu #rowIconMenu [popup]="true" [model]="rowIconMenuItems"></p-menu>
    <div class="properties-table">
        <p-table #table [value]="tableData" [globalFilterFields]="['name','value']" selectionMode="single"
                 [(selection)]="selectedRowData" dataKey="name" [scrollable]="true">
        <ng-template pTemplate="header">
            <tr>
                <th [pSortableColumn]="'name'" i18n>Name
                    <p-sortIcon *ngIf="hasData" [field]="'name'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'value'" i18n>Value
                    <p-sortIcon *ngIf="hasData" [field]="'value'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'isEncrypted'" i18n style="width:20%;">Encrypted
                    <p-sortIcon *ngIf="hasData" [field]="'isEncrypted'"></p-sortIcon>
                </th>
                <th class="col-btn" style="width: 80px;"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">
                <td>{{rowData.name}}</td>
                <td>{{(rowData.isEncrypted ? "&#8226;".repeat(10) : rowData.value)}}</td>
                <td>{{rowData.isEncrypted | yesNo}}</td>
                <td class="col-btn">
                    <a style="width:16px" (click)="onRowIconClick($event, rowData)">
                        <i class="fa fa-ellipsis-v"></i></a>
                </td>
            </tr>
        </ng-template>
    </p-table>
    </div>
</div>
