<div class="image-picker">
    <div class="flex-box">
        <div class="image-preview">
            <div *ngIf="!value" class="ig-row ig-row-align-center ig-advisory">No Image</div>
            <img *ngIf="value" [src]="domSanitizer.bypassSecurityTrustResourceUrl(value)"
                 [style.max-height.px]="previewHeight"
                 [style.max-width.px]="previewWidth" />
        </div>
        <div class="image-upload">
            <input #input name="imageUrl" type="file" accept="{{allowedFileTypes}}"
                   [disabled]="disabled"
                   (change)="handleInputChange($event)" />
            <button igButton
                    [disabled]="disabled"
                    igTooltip="{{chooseFileTooltip}}"
                    tooltipPosition="top"
                    tooltipStyleClass="ig-tooltip"
                    icon="fa-folder"
                    (click)="input.click()"></button>
            <button igButton
                    [disabled]="disabled"
                    igTooltip="{{restoreFileTooltip}}"
                    tooltipPosition="top"
                    tooltipStyleClass="ig-tooltip" icon="fa-refresh"
                    (click)="input.value = '';clearValue()"></button>
        </div>

        <div class="message">{{message}}</div>
    </div>
    <div *ngIf="hasError" class="ig-feedback">{{validationMessage}}</div>
</div>