<div class="ig-form-section" [formGroup]="formGroup">
    <div class="ig-field-v-ct">
        <label i18n>Select Start Date</label>
        <div class="ig-form-field">
            <p-calendar formControlName="startDate" [showIcon]="true"
                        [inputStyleClass]="(hasFieldRequiredError('startDate') ? 'ig-required' : '')"
                        placeholder="{{(''|IgConstants).REQUIRED}}"></p-calendar>
        </div>
    </div>
    <div class="ig-field-h-ct radio-group">
        <p-radioButton name="endByType" formControlName="endByType" value="END_BY_DATE" i18n-label
                       label="End date"></p-radioButton>
        <p-radioButton name="endByType" formControlName="endByType" value="NO_END" i18n-label
                       label="No end date"></p-radioButton>
        <p-radioButton name="endByType" formControlName="endByType" value="FIXED_NUMBER_OF_OCCURRENCES"
                       i18n-label label="Ends after"></p-radioButton>
    </div>
    <ng-container [ngSwitch]="endByType.value">
        <div class="ig-field-v-ct" *ngSwitchCase="'END_BY_DATE'">
            <label i18n>Select End Date</label>
            <div class="ig-form-field">
                <p-calendar formControlName="endDate" [showIcon]="true"
                            [inputStyleClass]="(hasFieldRequiredError('endDate') ? 'ig-required' : '')"
                            placeholder="{{(''|IgConstants).REQUIRED}}"></p-calendar>
            </div>
            <ng-container *ngIf="isFieldInvalid('endDate')">
                        <span *ngIf="endDate.errors?.invalidEndDate"
                              class="ig-feedback" i18n>End date must be after start date</span>
            </ng-container>
        </div>

        <div class="ig-field-h-ct" *ngSwitchCase="'FIXED_NUMBER_OF_OCCURRENCES'">
            <div class="ig-field-v-ct">
                <label i18n>Number of occurrences</label>
                <div class="ig-form-field ig-w-c12">
                    <p-spinner formControlName="numberOfOccurrence" min="1" max="1000"
                               placeholder="{{(''|IgConstants).REQUIRED}}"
                               [inputStyleClass]="(hasFieldRequiredError('numberOfOccurrence') ? 'ig-required' : '')"
                    ></p-spinner>
                </div>
            </div>
            <div class="ig-field-h-ct no-label-ct">
                <label i18n>Occurrences</label>
            </div>
        </div>
    </ng-container>
<!--    {{formGroup.value | json}}-->
</div>