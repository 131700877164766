<div>
    <form [formGroup]="formGroup" autocomplete="off">
        <p-accordion [multiple]="true">
            <!--
            -----------------------------------------
            Connection Configuration
            -----------------------------------------
            -->
            <p-accordionTab i18n-header [selected]="true">
                <p-header>
                    <span i18n class="p-accordion-header-text">Govern Connection Configuration</span>
                    <ig-field-error-summary [formModel]="formGroup"
                                            [formControlNames]="['governURL', 'governKey', 'governSecret']">

                    </ig-field-error-summary>
                </p-header>

                <div class="ig-form-section">
                    <div class="ig-field-v-ct ig-stretch">
                        <label i18n>Server URL</label>
                        <div class="ig-form-field  ig-w-c10">
                            <input pInputText formControlName="governURL" type="text"/>
                            <ng-container *ngIf="isFieldInvalid('governURL')">
                               <span *ngIf="governURL.errors?.governURL" class="ig-feedback"
                                     i18n>URL must be in the format https://hostname:port</span>
                            </ng-container>
                        </div>
                    </div>
                    <div class="ig-field-v-ct ig-stretch">
                        <label i18n>API Key</label>
                        <div class="ig-form-field  ig-w-c10">
                            <input pInputText autocomplete="whatever" formControlName="governKey" type="text"/>
                        </div>
                    </div>
                    <div class="ig-field-v-ct ig-stretch">
                        <label i18n>API Secret</label>
                        <div class="ig-form-field  ig-w-c10">
                            <dqp-encrypted-text-field #secretInput
                                                      formControlName="governSecret"
                                                      [styleClass]="{'ig-w-100pc':true}">
                            </dqp-encrypted-text-field>
                        </div>
                    </div>
                </div>
                <div class="ig-margin-m-t">
                    <p-accordion [multiple]="false">
                        <p-accordionTab i18n-header [selected]="false">
                            <p-header>
                                <span i18n class="p-accordion-header-text">Proxy Connection Configuration</span>
                                <ig-field-error-summary [formModel]="formGroup"
                                                        [formControlNames]="['proxyURL', 'proxyAuthentication', 'proxyUsername',
                                            'proxyPassword']">
                                </ig-field-error-summary>
                            </p-header>
                            <div class="ig-form-section">
                                <div class="ig-field-v-ct ig-stretch">
                                    <label i18n>Proxy URL</label>
                                    <div class="ig-form-field  ig-w-c10">
                                        <input pInputText formControlName="proxyURL" type="text"/>
                                        <ng-container *ngIf="isFieldInvalid('proxyURL')">
                               <span *ngIf="proxyURL.errors?.proxyURL" class="ig-feedback"
                                     i18n>URL must be in the format https://hostname:port</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="ig-field-v-ct ig-stretch">
                                    <label i18n>Proxy Authentication</label>
                                    <div class="ig-field-h-ct ig-w-c10">
                                        <p-dropdown [options]="proxyAuthenticationOptions"
                                                    formControlName="proxyAuthentication"
                                                    i18n-placeholder placeholder="Select..."
                                                    (onChange)="onChangeProxyAuthenticationHandler($event)"
                                        >
                                        </p-dropdown>
                                    </div>
                                </div>
                                <div class="ig-field-v-ct ig-stretch">
                                    <label i18n>Proxy Username</label>
                                    <div class="ig-form-field  ig-w-c10">
                                        <input pInputText autocomplete="whatever" formControlName="proxyUsername" type="text"
                                               [required]="isProxyUserAndPasswordRequired()"
                                               [ngClass]="(hasFieldRequiredError('proxyUsername') ? ' ig-required' : '')"
                                               placeholder="{{getFieldPlaceholder()}}"/>
                                    </div>
                                </div>
                                <div class="ig-field-v-ct ig-stretch">
                                    <label i18n>Proxy Password</label>
                                    <div class="ig-form-field  ig-w-c10">
                                        <dqp-encrypted-text-field #proxyPasswordInput
                                                                  formControlName="proxyPassword"
                                                                  [styleClass]="{'ig-w-100pc':true, 'ig-required':hasFieldRequiredError('proxyPassword')}"
                                                                  placeholder="{{getFieldPlaceholder()}}"
                                                                  [required]="isProxyUserAndPasswordRequired()">
                                        </dqp-encrypted-text-field>
                                    </div>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="ig-field-h-ct ig-w-c10 ig-margin-m-t" style="justify-content: space-between;">
                    <div style="justify-content: flex-start">
                        <button (click)="onTestConnectionButtonClick()"
                                [disabled]="!isConnectionConfigured()"
                                type="button"
                                style="margin-right: 4px;align-self: flex-start;"
                                igButton i18n-label label="Test Connection"></button>
                        <span *ngIf="connectionTestStatus == 'TESTING'" class="connection-status" i18n><i
                                class="fa fa-spinner fa-spin ig-margin-xs-r"></i>Testing...</span>
                        <span *ngIf="connectionTestStatus == 'SUCCESS'" class="connection-status"
                              style="color:#00853E;" i18n>Success!</span>
                        <span *ngIf="connectionTestStatus == 'FAILED'" class="connection-status"
                              style="color:#D11947" i18n>Connection Failed</span>
                    </div>
                    <div style="justify-content: flex-end">
                                <span *ngIf="configureTechnicalAssetsStatus == 'IN_PROGRESS'"
                                      class="technical-asset-creation-status" i18n><i
                                        class="fa fa-spinner fa-spin ig-margin-xs-r"></i>Creating ({{ percentComplete }}
                                    %)</span>
                        <span *ngIf="configureTechnicalAssetsStatus == 'UPDATED'"
                              class="technical-asset-creation-status"
                              style="color:#00853E;" i18n>Updated!</span>
                        <span *ngIf="configureTechnicalAssetsStatus == 'FINISHED'"
                              class="technical-asset-creation-status"
                              style="color:#00853E;" i18n>Finished!</span>
                        <span *ngIf="configureTechnicalAssetsStatus == 'FAILED'"
                              class="technical-asset-creation-status"
                              style="color:#D11947" i18n>Failed</span>
                        <button (click)="onCreateTechnicalAssetsButtonClick()"
                                [disabled]="areTechnicalAssetsCreated || configureTechnicalAssetsStatus !== null"
                                type="button"
                                style="margin-left:4px;align-self: flex-start;"
                                igButton i18n-label label="Create Asset Types"></button>
                    </div>
                </div>
            </p-accordionTab>
            <p-accordionTab i18n-header [selected]="true">
                <p-header>
                    <span i18n class="p-accordion-header-text">DQ+ Integration</span>
                    <ig-field-error-summary [formModel]="formGroup"
                                            [formControlNames]="['governFusionId', 'governArtifactForSynonyms']">
                    </ig-field-error-summary>
                </p-header>
                <div class="ig-form-section">
                    <div class="ig-field-v-ct ig-stretch">
                        <label i18n>Root DQ+ Asset</label>
                        <div class="ig-field-h-ct ig-w-c10">
                            <div class="ig-form-field ig-grow">
                                <p-dropdown [options]="availableFusionConfigs"
                                            formControlName="governFusionId"
                                            [disableControl]="!isConnectionConfigured() || !areTechnicalAssetsCreated"
                                            i18n-placeholder placeholder="Select..."
                                >
                                </p-dropdown>
                            </div>
                            <button igButton icon="fa-plus"
                                    (click)="onNewRoot($event)"
                                    [disabled]="isNew() || !isConnectionConfigured() || !areTechnicalAssetsCreated"
                                    i18n-label label="New Root"></button>
                        </div>
                    </div>
                    <div class="ig-field-v-ct ig-stretch">
                        <label i18n>Business Asset Type for Synonyms</label>
                        <div class="ig-form-field  ig-w-c10">
                            <p-dropdown [options]="businessAssetTypesForSynonyms"
                                        formControlName="governArtifactForSynonyms"
                                        filter="true"
                                        [disableControl]="!isConnectionConfigured()"
                                        (onFilter)="onFilter($event)"
                                        (onShow)="onShow()"
                            >
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </p-accordionTab>
            <!--
    -----------------------------------------
    Govern Metadata Synchronization Schedule
    -----------------------------------------
    -->
            <p-accordionTab i18n-header [selected]="true" [hidden]="isNew()">
                <p-header>
                    <span i18n class="p-accordion-header-text">Govern Metadata Synchronization Schedule</span>
                    <ig-field-error-summary [formModel]="formGroup" [formControlNames]="['scheduleCalendar']">
                    </ig-field-error-summary>
                </p-header>
                <div class="ig-form-section">
                    <dqp-schedule-calendar [value]="scheduleCalendarFormValues"></dqp-schedule-calendar>
                    <div class="ig-field-h-ct" style="justify-content: flex-end;margin-right:-1em;">
                        <span *ngIf="synchronizeNowStatus == 'IN_PROGRESS'" class="connection-status" i18n><i
                                class="fa fa-spinner fa-spin ig-margin-xs-r"></i>Synchronizing...</span>
                        <span *ngIf="synchronizeNowStatus == 'SUCCESS'" class="connection-status" style="color:#00853E;"
                              i18n>Started push of all environment stages metadata to Govern application</span>
                        <span *ngIf="synchronizeNowStatus == 'FAILED'" class="connection-status" style="color:#D11947"
                              i18n>Failed to push metadata to Govern application</span>
                        <span *ngIf="synchronizeNowStatus == 'CONNECTION_FAILED'" class="connection-status"
                              style="color:#D11947" i18n>Connection Failed. Please check the connection parameters and test them.</span>
                        <button (click)="onSynchronizeNowButtonClick()" [disabled]="disableSynchronizeNow" igButton icon="fa-refresh"
                                type="button"
                                class="ig-button-primary"
                                i18n-label label="Synchronize Now"></button>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
    </form>
</div>
<!--<hr/>-->
<!--<div>{{this.formGroup.value | json }}</div>-->
