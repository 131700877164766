<h1 class="ig-title" i18n>Recently Updated</h1>
<section class="dqp-recently-updated-panel">
    <div class="coach-text" i18n *ngIf="!loading && (recentlyUpdatedItems?.length <= 0)">
        Pipeline stages which you have recently updated will be listed here, to quickly choose and continue working on them.
    </div>
    <dqp-stage-item-list
            [items]="recentlyUpdatedItems"
            [loading]="loading"
            (deleteClick)="onItemDelete($event)"
            (itemClick)="onItemClick($event)"
            [deleteButtonTooltip]="deleteButtonTooltip"
    ></dqp-stage-item-list>
</section>