<div class="dqp-view bom-group">
    <!--    North panel-->
    <div class="ig-w-100pc dqp-toolbar top-header">
            <div class="dqp-title-icon"><i [class]="'fa ' + selectedBomItemNodeCls"></i></div>
            <ng-container *ngFor="let nextPathToken of selectedBomItemPathTokens;let i=index;let last=last">
                <span *ngIf="i > 0" class="fa fa-angle-right"></span>
                <span>{{nextPathToken}}</span>
            </ng-container>
    </div>

    <!--    South panel-->
    <div class="p-grid ig-w-100pc south-panel">
        <as-split direction="horizontal" [gutterSize]="8" [unit]="'pixel'" (dragEnd)="onSplitterDragEnd($event)">
            <as-split-area #leftSplitArea class="left-split-area" [size]="getInitialLeftPanelWidth()"
                           [minSize]="200" [maxSize]="getLeftPanelMaxWidth()">
                <!--
                  ----------------------------------------------
                  Left panel
                  ----------------------------------------------
                -->
                <div class="p-grid left-panel collapsible" [class.collapsed]="leftPanelCollapsed" (click)="onLeftPanelClick()">
                    <button class="expand-button" igButton icon="fa-arrow-right"
                            i18n-igTooltip igTooltip="Open" tooltipPosition="right"
                            (click)="onExpandButtonClick($event)"></button>
                    <div class="ig-w-100pc left-panel-content">
                        <form [formGroup]="formGroup" (keydown.enter)="$event.preventDefault()">
                            <div class="dqp-toolbar">
                                <ig-search-field class="ig-grow"
                                                 formControlName="searchText"
                                                 [disableControl]="isDisableSearchField()"
                                                 i18n-placeholder placeholder="Search Pipelines..."></ig-search-field>
                                <button igButton icon="fa-arrow-left"
                                        i18n-igTooltip igTooltip="Close" tooltipPosition="right"
                                        (click)="onCollapseButtonClick($event)"></button>
                            </div>
                            <ig-multi-select class="ig-padding-m-t"
                                             formControlName="stageFilterItems"
                                             panelStyleClass="bom-group-filter"
                                             [disableControl]="isDisableFilterMultiSelect()"
                                             [filter]="false"
                                             [showHeader]="false"
                                             [showToggleAll]="false"
                                             [options]="availableStageTypes"
                                             [scrollHeight]="'400px'"
                                             i18n-placeholder placeholder="Filter..."
                                             maxSelectedLabels="3">
                            </ig-multi-select>
                        </form>
                        <div class="dqp-toolbar ig-padding-m-t">
                            <button igButton icon="fa-refresh"
                                    i18n-igTooltip igTooltip="Refresh" tooltipPosition="top"
                                    (click)="onRefreshClick()" ></button>
                            <button *ngIf="addPipelineButtonVisible" igButton icon="fa-plus"
                                    i18n-igTooltip igTooltip="New Pipeline" tooltipPosition="top"
                                    (click)="onAddButtonClick()"></button>
                            <span class="ig-grow"></span>
                            <p-menu #topKebabMenu [popup]="true" [model]="topKebabMenuItems"></p-menu>
                            <button igButton icon="fa-ellipsis-v" (click)="onTopKebabButtonClick($event)"></button>
                        </div>

                        <dqp-pipeline-tree #pipelineTree class="ig-padding-s-t" [bomFilter]="bomFilter"
                                           (selectionChange)="onTreeSelectionChange($event)"></dqp-pipeline-tree>
                    </div>
                </div>
            </as-split-area>
            <as-split-area>
                <!--
                  ----------------------------------------------
                  right panel
                  ----------------------------------------------
                -->
                <div class="right-panel">
                    <div class="bom-preview-content">
                        <router-outlet (activate)="onRouterOutletActivate($event)"
                                       (deactivate)="onRouterOutletDeactivate($event)"></router-outlet>
                    </div>
                </div>
            </as-split-area>
        </as-split>
    </div>
</div>
