<div class="tile-item-content" [ngClass]="{'empty-tile':tile.isEmptyTile()}">
    <ng-container *ngIf="tile.isEmptyTile()">
        <!-- Empty tile -->
        <div class="tile-item-ct icon-tile empty-tile">
        </div>
    </ng-container>
    <ng-container *ngIf="!tile.isEmptyTile()">
        <!-- Non-empty tile -->
        <div class="tile-item-ct ig-clickable" [ngClass]="{'icon-tile':iconStyleClass}"
             [style.backgroundImage]="backgroundImage" (click)="onTileItemClick($event)">
            <div *ngIf="iconStyleClass" class="icon-tile-img" [ngClass]="iconStyleClass"></div>
        </div>
        <section class="tile-item-label">
            <div class="tile-item-title" >{{tile.name}}</div>
            <div class="tile-item-description">{{tile.description}}</div>
        </section>
    </ng-container>
</div>