<div class="dqp-view">
    <div class="p-col-12 dqp-toolbar ig-padding-m-b ig-row-justify-end">
        <button (click)="onEditLayoutClick($event)"
                class="ig-margin-s-v"
                i18n-label igButton
                label="Edit Layout"
                *ngIf="editMode">
        </button>
        <button (click)="onPreviewClick($event)"
                [disabled]="isEmptyScreenDef()"
                class="ig-margin-s-v"
                [style.padding-right]="editMode ? '8px' : '16px'"
                i18n-label igButton
                label="Preview"></button>
        <span class="toolbar-buttons-separator" *ngIf="editMode"> </span>
        <button (click)="onAcceptChangesClick($event)"
                [disabled]="!dirty"
                class="ig-button-primary ig-margin-s-v"
                i18n-label igButton
                label="Accept Changes"
                *ngIf="editMode">
        </button>
    </div>
    <form [formGroup]="formGroup">
        <p-accordion [multiple]="true">
            <p-accordionTab [selected]="true">
                <p-header>
                    <span class="p-accordion-header-text" i18n>Layout Details</span>
                </p-header>
                <ng-template pTemplate="content">
                    <div class="ig-data-tbl">
                        <p-table #layoutDetailsTable [value]="getScreenProperties()" selectionMode="single">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 35%;" i18n>Name</th>
                                    <th style="width: 15%;" i18n>Default Label Position</th>
                                    <th style="width: 10%;" i18n>Rows</th>
                                    <th style="width: 10%;" i18n>Columns</th>
                                    <th style="width: 15%;" i18n>Column Width</th>
                                    <th style="width: 15%;" i18n>Column Height</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr [pSelectableRow]="rowData">
                                    <td>{{ rowData.displayName }}</td>
                                    <td>{{ rowData.labelPosition | labelPosition }}</td>
                                    <td>{{ rowData.rowCount }}</td>
                                    <td>{{ rowData.columnCount }}</td>
                                    <td>{{ rowData.minWidth }}</td>
                                    <td>{{ rowData.minHeight }}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </ng-template>
            </p-accordionTab>
            <p-accordionTab [selected]="true">
                <p-header>
                    <span class="p-accordion-header-text" i18n>Field Details</span>
                </p-header>
                <ng-template pTemplate="content">
                    <div class="ig-data-tbl">
                        <p-table #fieldDetailsTable [value]="getScreenFields()" selectionMode="single"
                                 styleClass="p-datatable-striped" [globalFilterFields]="['fieldName']">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th [pSortableColumn]="'fieldName'" style="width: 15%;" i18n>Field
                                        <p-sortIcon [field]="'name'"></p-sortIcon>
                                    </th>
                                    <th style="width: 15%;" i18n>Label</th>
                                    <th style="width: 15%;" i18n>Type</th>
                                    <th style="width: 15%;" i18n>Label Position</th>
                                    <th style="width: 10%;" i18n>Row Span</th>
                                    <th style="width: 10%;" i18n>Col Span</th>
                                    <th style="width: 10%;" i18n>Required</th>
                                    <th style="width: 10%;" i18n>Read Only</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData>
                                <tr [pSelectableRow]="rowData">
                                    <td>{{ rowData.fieldName }}</td>
                                    <td>{{ rowData.label }}</td>
                                    <td>{{ rowData.componentType | componentType }}</td>
                                    <td>{{ rowData.labelPosition | labelPosition }}</td>
                                    <td>{{ rowData.rowSpan }}</td>
                                    <td>{{ rowData.colSpan }}</td>
                                    <td>{{ rowData.required | yesNo }}</td>
                                    <td>{{ rowData.readOnly | yesNo }}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </ng-template>
            </p-accordionTab>
        </p-accordion>
    </form>
</div>

