import {MonthlyRecurrencePatternType, RecurrenceEndType, YearlyRecurrencePatternType} from "../calendar.model";

export enum RecurrenceFrequency {
    ONE_TIME = "o",
    HOURLY = "h",
    DAILY = "d",
    WEEKLY = "w",
    MONTHLY = "m",
    YEARLY = "y",
    CRON_EXPR = "c"
}

export interface RangeOfOccurrence {
    startDate:Date;
    endDate:Date;
    endByType: RecurrenceEndType;
    numberOfOccurrence: number;
}

export interface RecurrenceFrequencyDetail {
}

export interface OneTimeRecurrenceFrequencyDetail extends RecurrenceFrequencyDetail{
    oneTimeDate: Date;
}

export interface HourlyRecurrenceFrequencyDetail extends RecurrenceFrequencyDetail {
    recurEveryMinHour: null;
    minOrHours: "MINS" | "HRS";
    rangeOfRecurrence: RangeOfOccurrence;
}

export interface DailyRecurrenceFrequencyDetail extends RecurrenceFrequencyDetail {
    numberOfDays:number;
    recurEveryWeekday:boolean[]; // use array because of primeng bug in checkbox
    rangeOfRecurrence: RangeOfOccurrence;
}

export interface WeeklyRecurrenceFrequencyDetail extends RecurrenceFrequencyDetail {
    recurEveryWeek:number;
    recurDays:string[];
    rangeOfRecurrence: RangeOfOccurrence;
}

export interface MonthlyRecurrenceFrequencyDetail extends RecurrenceFrequencyDetail {
    recurrencePatternType: MonthlyRecurrencePatternType,
    // day of the month 1 - 31
    dayOfMonth:number;
    // week of the month 1 - 4
    weekOfMonth:number;
    // day of the week Sunday = 1, Monday = 2, Tuesday = 3, Wednesday = 4, Thursday = 5, Friday = 6, Saturday = 7
    dayOfWeek:number;
    // every n month 1, 2 etc.
    recurEveryMonths:number;

    rangeOfRecurrence: RangeOfOccurrence;
}

export interface YearlyRecurrenceFrequencyDetail extends RecurrenceFrequencyDetail {
    recurrencePatternType:YearlyRecurrencePatternType,
    // day of the month 1 - 31
    dayOfMonth:number;
    // week of the month 1 - 4
    weekOfMonth:number;
    // day of the week Sunday = 1, Monday = 2, Tuesday = 3, Wednesday = 4, Thursday = 5, Friday = 6, Saturday = 7
    dayOfWeek:number;
    // month of the year 1 - 12
    monthOfYear:number;
    // recurring every n years where n >= 1
    recurEveryYears:number;

    rangeOfRecurrence: RangeOfOccurrence;
}
export interface CronExpressionRecurrenceFrequencyDetail extends RecurrenceFrequencyDetail {
    cronExpression:string;
    rangeOfRecurrence: RangeOfOccurrence;
}

export interface ScheduleCalendarFormValues {
    timeZone:string;
    executionTime: Date;
    recurrenceFrequency: RecurrenceFrequency;
    recurrenceFrequencyDetail: RecurrenceFrequencyDetail;
}