<!--suppress TypeScriptValidateTypes -->
<p-menu #rowKebabMenu [popup]="true" [model]="rowKebabMenuItems" [appendTo]="'body'"></p-menu>
<ng-container *ngFor="let rowData of rows; let rowIndex = index;">
    <div [formGroup]="rowData" class="tile-link-row">
        <div class="header">
            <label i18n>URL and Description Group {{rowIndex + 1}}</label>
            <button *ngIf="rows.length > 1" igButton icon="fa-ellipsis-v"
                    (click)="onRowKebabClick($event, rowData, rowIndex)"></button>
        </div>

        <div class="ig-field-v-ct">
            <label i18n>Tile URL</label>
            <div class="ig-form-field ig-margin-m-b ig-w-100pc">
                <input igFormControl pInputText maxlength="250" type="text" formControlName="url"/>
                <span *ifErrorForField="'url'" class="ig-feedback" i18n>Invalid URL</span>
            </div>
        </div>
        <div class="ig-field-v-ct ig-margin-m-b">
            <label i18n>URL Description</label>
            <div class="ig-form-field ig-w-100pc">
                <input igFormControl pInputText maxlength="250" type="text" formControlName="label"/>
            </div>
        </div>
    </div>
</ng-container>
<div>
    <button igButton icon="fa-plus-circle"
            class="ig-link-button" i18n-label label="Add new Tile URL and Description"
            (click)="onAddNewTileLinkClick()"></button>
</div>


<!--<div>{{formArray.value | json}}</div>-->