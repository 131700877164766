<div style="height: 770px;">
    <form [formGroup]="formGroup">
        <p-tabView class="igx-filled-tabs" [(activeIndex)]="index">
            <!--
                       Screen Properties tab
            -->
            <p-tabPanel header="Screen Properties" i18n-header>
                <div [formGroup]="fgScreenDefinition">
                    <div class="ig-field-v-ct ig-padding-m-t ig-stretch">
                        <label class="name" i18n>Name</label>
                        <div class="ig-form-field">
                            <input formControlName="displayName" maxlength="100"
                                   pInputText placeholder="{{(''|IgConstants).REQUIRED}}" type="text" (input)="onChangedByUser($event)"/>
                        </div>
                    </div>
                    <div class="ig-field-h-ct ig-padding-s-t">
                        <div class="ig-field-v-ct">
                            <label class="name" i18n>Row Height (px)</label>
                            <div class="ig-form-field">
                                <input formControlName="minHeight"
                                       pInputText placeholder="{{(''|IgConstants).REQUIRED}}" type="number" min="40" max="120"
                                       (change)="onChangedByUser($event)"/>
                            </div>
                        </div>
                        <div class="ig-field-v-ct">
                            <label class="name" i18n>Column Width (px)</label>
                            <div class="ig-form-field">
                                <input formControlName="minWidth" placeholder="{{(''|IgConstants).REQUIRED}}" min="200" max="500"
                                       pInputText type="number"
                                       (change)="onChangedByUser($event)"/>
                            </div>
                        </div>
                    </div>
                    <div class="ig-field-v-ct ig-padding-s-t ig-padding-l-b ig-stretch">
                        <label class="name" i18n>Label Position</label>
                        <p-dropdown formControlName="labelPosition"
                                    i18n-placeholder placeholder="Label Position"
                                    [options]="labelPositionOptions"
                                    optionLabel="label" optionValue="value"
                                    (onChange)="onChangedByUser($event)"
                        >
                        </p-dropdown>
                    </div>
                    <!--  additional space 64px tall so drop down is not chopped off -->
                    <div class="ig-padding-l-v"></div>
                </div>
            </p-tabPanel>
            <!--
                       Field Properties tab
            -->
            <p-tabPanel header="Field Properties" i18n-header>
                <div [hidden]="editedField !== null" class="selection-indicator">
                    <span [hidden]="numSelectedFields > 0">No selection</span>
                    <span [hidden]="numSelectedFields === 0">{{ numSelectedFields }} fields selected</span>
                </div>
                <div [formGroup]="fgFieldDefinition" [hidden]="editedField === null">
                    <!--  Name -->
                    <div class="ig-field-v-ct ig-padding-m-t ig-stretch">
                        <label class="name" i18n>Field</label>
                        <div class="ig-form-field">
                            <p-dropdown formControlName="fieldName"
                                        i18n-placeholder placeholder="Field name"
                                        [options]="availableFields"
                                        optionLabel="label" optionValue="value"
                                        (onChange)="onFieldNameChanged($event)"
                            >
                            </p-dropdown>
                        </div>
                    </div>
                    <!--  Label -->
                    <div class="ig-field-v-ct ig-padding-s-t ig-stretch">
                        <label class="name" i18n>Label</label>
                        <div class="ig-form-field">
                            <input formControlName="label" i18n-placeholder maxlength="100"
                                   pInputText placeholder="Label" type="text"
                                   (input)="onChangedFieldByUser($event)"
                            />
                        </div>
                    </div>
                    <!-- Type -->
                    <div class="ig-field-v-ct ig-padding-s-t ig-stretch">
                        <label class="name" i18n>Type</label>
                        <div class="ig-form-field">
                            <p-dropdown formControlName="componentType"
                                        i18n-placeholder placeholder="Type"
                                        [options]="availableControlTypes"
                                        optionLabel="label" optionValue="value"
                                        (onChange)="onChangedFieldByUser($event)"
                            >
                            </p-dropdown>
                        </div>
                    </div>
                    <!-- Label position -->
                    <div class="ig-field-v-ct ig-padding-s-t ig-stretch">
                        <label class="name" i18n>Label Position</label>
                        <p-dropdown formControlName="labelPosition"
                                    i18n-placeholder placeholder="Label Position"
                                    [options]="fieldLabelPositionOptions"
                                    optionLabel="label" optionValue="value"
                                    (onChange)="onChangedFieldByUser($event)"
                        >
                        </p-dropdown>
                    </div>
                    <!-- Row span & column span -->
                    <div class="ig-field-h-ct ig-padding-s-t">
                        <div class="ig-field-v-ct">
                            <label class="name" i18n>Row Span</label>
                            <div class="ig-form-field">
                                <input formControlName="rowSpan"
                                       pInputText type="number"
                                       min="1" [max]="maxRowSpan"
                                       (change)="onChangedFieldByUser($event)"
                                />
                            </div>
                        </div>
                        <div class="ig-field-v-ct">
                            <label class="name" i18n>Column Span</label>
                            <div class="ig-form-field">
                                <input formControlName="colSpan"
                                       pInputText type="number"
                                       min="1" [max]="maxColumnSpan"
                                       (change)="onChangedFieldByUser($event)"
                                />
                            </div>
                        </div>
                    </div>
                    <!-- this div eliminates chopping off the checkboxes -->
                    <div style="padding-left: 2px;">
                        <div class="ig-field-v-ct ig-padding-m-t">
                            <p-checkbox formControlName="required"
                                        [binary]="true"
                                        i18n-label label="Required"
                                        (onChange)="onChangedFieldByUser($event)"
                            >
                            </p-checkbox>
                        </div>
                        <div class="ig-field-v-ct ig-padding-m-t ig-padding-l-b">
                            <p-checkbox formControlName="readOnly"
                                        [binary]="true"
                                        i18n-label label="Read Only"
                                        (onChange)="onChangedFieldByUser($event)"
                            >
                            </p-checkbox>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </form>
</div>