<dqp-my-account-overlay #myAccountOverlayPanel></dqp-my-account-overlay>
<p-menu #helpMenuPopup [popup]="true" [model]="helpMenuItems" ></p-menu>
<div class="headerbar">
    <img class="logo" [src]="logoImage" alt="Data360 DQ+"
         (click)="onLogoClick($event)" />
    <dqp-menubar [model]="menubarItems" class="ig-grow" [style.min-width]="0">
        <div class="items-ct">
            <p-dropdown *ngIf="devEnvs?.length > 0" [options]="devEnvs" [(ngModel)]="selectedDevEnv" optionLabel="name" placeholder=" "
                        [appendTo]="'body'"
                        [filter]="devEnvs?.length > 5" i18n-filterPlaceholder filterPlaceholder="Search Environments..."
                        resetFilterOnHide="true">
                <ng-template let-item pTemplate="selectedItem">
                    <span class="item-icon"><i class="fa fa-globe"></i></span>
                    <span style="vertical-align:middle; margin-left: .5em">{{item.name}}</span>
                </ng-template>
            </p-dropdown>
            <button class="toggle-fullscreen ig-icon ig-button" i18n-title title="Toggle Fullscreen" (click)="onToggleFullScreen($event)"><i class="fa fa-expand"></i></button>
            <button class="help ig-icon ig-button" i18n-title title="Help" (click)="helpMenuPopup.toggle($event)"><i class="fa fa-question-circle"></i></button>
            <button class="my-account ig-icon ig-button" i18n-title title="My Account" (click)="myAccountOverlayPanel.toggle($event)">
                @if (profileImage) {
                    <img [src]="profileImage" style="width: 16px;height: 16px"/>
                } @else {
                    <i class="fa fa-user"></i>
                }
            </button>
        </div>
    </dqp-menubar>
</div>