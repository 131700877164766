<div [formGroup]="formGroup">
    <div class="ig-form-section">
        <div class="ig-field-v-ct">
            <label i18n>Header Logo Image</label>
            <div class="ig-form-field ig-w600">
                <dqp-image-picker #logoImagePicker
                                  [defaultImage]="DEFAULT_LOGO"
                                  formControlName="logoImage"
                                  [maxSize]="1048576"
                                  i18n-message message="Choose a PNG, JPG, or GIF file with a maximum size of 1 MB."
                ></dqp-image-picker>
            </div>
        </div>
        <div class="ig-field-v-ct">
            <label i18n>Website Icon (Favicon)</label>
            <div class="ig-form-field ig-w600">
                <dqp-image-picker #faviconImagePicker
                                  [defaultImage]="DEFAULT_FAVICON"
                                  imageType="FAVICON"
                                  formControlName="favicon"
                                  [maxSize]="1048576"
                                  i18n-message message="Choose a PNG or ICO file with a maximum size of 1 MB."
                ></dqp-image-picker>
            </div>
        </div>
    </div>
</div>