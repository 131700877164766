<div class="ig-field-v-ct" [formGroup]="formGroup">
    <label class="detail-header" i18n>Yearly Frequency</label>
    <div class="ig-form-section">
        <div class="ig-field-h-ct">
            <div class="ig-field-v-ct">
                <label i18n>Recur every</label>
                <div class="ig-field-h-ct">
                    <p-spinner formControlName="recurEveryYears" min="1" max="100"
                               [inputStyleClass]="this.hasFieldRequiredError('recurEveryYears') ? 'ig-required' :''"
                               placeholder="{{(''|IgConstants).REQUIRED}}"
                    >
                    </p-spinner>
                    <label>Years</label>
                </div>
            </div>
        </div>
        <div class="ig-field-h-ct radio-group" style="margin-bottom: 32px">
            <p-radioButton name="recurrencePatternType" formControlName="recurrencePatternType" value="FIXED_DATE" i18n-label
                           label="Standard calendar day"></p-radioButton>
            <p-radioButton name="recurrencePatternType" formControlName="recurrencePatternType"
                           value="NTH_WEEK_OF_X_DAY_OF_MONTH_Y" i18n-label
                           label="Specific day"></p-radioButton>
        </div>


        <ng-container [ngSwitch]="recurrencePatternType.value">
            <div *ngSwitchCase="'FIXED_DATE'" class="ig-field-h-ct label-ct" style="margin-bottom: 32px;">
                <label i18n>Select</label>
                <div class="medium-space">
                    <p-dropdown formControlName="monthOfYear"
                                placeholder="{{(''|IgConstants).REQUIRED}}"
                                [options]="availableMonthsOptions"
                                [styleClass]="(hasFieldRequiredError('monthOfYear') ? ' ig-required' : '')">
                    </p-dropdown>
                </div>
                <p-spinner formControlName="dayOfMonth" min="1" [max]="maxDayOfMonth"
                           [inputStyleClass]="this.hasFieldRequiredError('dayOfMonth') ? 'ig-required' :''"
                           placeholder="{{(''|IgConstants).REQUIRED}}"></p-spinner>
            </div>
            <div *ngSwitchCase="'NTH_WEEK_OF_X_DAY_OF_MONTH_Y'" class="ig-field-h-ct label-ct"
                 style="margin-bottom: 32px;">
                <label i18n>Select</label>
                <p-dropdown formControlName="weekOfMonth"
                            placeholder="{{(''|IgConstants).REQUIRED}}"
                            [options]="availableWeeksOfMonthOptions"
                            [styleClass]="(hasFieldRequiredError('weekOfMonth') ? ' ig-required' : '')">
                </p-dropdown>
                <p-dropdown formControlName="dayOfWeek"
                            placeholder="{{(''|IgConstants).REQUIRED}}"
                            [options]="availableDaysOfWeekOptions"
                            [styleClass]="(hasFieldRequiredError('dayOfWeek') ? ' ig-required' : '')">
                </p-dropdown>
                <label i18n>of</label>
                <p-dropdown formControlName="monthOfYear"
                            placeholder="{{(''|IgConstants).REQUIRED}}"
                            [options]="availableMonthsOptions"
                            [styleClass]="(hasFieldRequiredError('monthOfYear') ? ' ig-required' : '')">
                </p-dropdown>
            </div>
        </ng-container>

        <dqp-calendar-range-of-recurrence [style.display]="retentionScheduleMode ? 'none': 'block'" [value]="rangeOfRecurrenceFormValues"></dqp-calendar-range-of-recurrence>
    </div>
</div>