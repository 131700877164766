import {AfterViewInit, Component, OnDestroy, ViewEncapsulation} from "@angular/core";
import {Subscription} from "rxjs";
import {NavigationEnd, NavigationExtras, Router} from "@angular/router";
import {
    AppService,
    ExtJsService,
    LicenseService,
    RouterUtil,
    ViewChangeEvent,
    ViewportService
} from "@cafe/web-ui-framework";
import {RuleLibraryService} from "@cafe/bom-group";
import {Settings, SettingsService, TenantThemeService, HeaderBarTheme} from "@cafe/business-account-management";

@Component({
    selector: "dqp-app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements AfterViewInit, OnDestroy {
    title = "Data360 DQ+";

    private _showTopNav = true;
    public isShowingExtJsView = true;

    private subscriptions = new Subscription();

    constructor(private viewportService: ViewportService, private router: Router, private extjsService: ExtJsService,
                private appService: AppService, private routerUtil:RouterUtil,
                private licenseService: LicenseService,
                private ruleLibraryService: RuleLibraryService,
                private themeService:TenantThemeService,
                private settingsService:SettingsService) {
        this.subscriptions.add(viewportService.viewChange.subscribe((viewChangeEvent: ViewChangeEvent) => {
            this.isShowingExtJsView = viewChangeEvent.isShowingExtJs;
        }));
        this.subscriptions.add(
            // listen for extjs event about target URL redirect
            extjsService.onExtJsEvent("redirectToTargetPage").subscribe(([targetHash])=> {
                console.log("Handling redirectToTargetPage event from angular, targetHash=", targetHash);
                this.router.navigateByUrl(targetHash, {queryParamsHandling:"preserve"} as NavigationExtras);
            })
        );

        this.subscriptions.add(router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.showTopNav = true;
                const url = event.urlAfterRedirects;
                this.isShowingExtJsView = !this.routerUtil.isSupportedUrl(url);
                this.viewportService.isShowingExtJsView = this.isShowingExtJsView;
                if (this.isShowingExtJsView) {
                    this.viewportService.notifyLayoutChange();
                }
            }
        }));
    }

    get isPortalMode(): Boolean {
        return this.appService.isPortalMode;
    }

    get showTopNav(): boolean {
        return this._showTopNav;
    }

    set showTopNav(value: boolean) {
        this._showTopNav = value;
    }

    public onRouterOutletActivate(routerComponent: any) {
        this.viewportService.currentViewportComponent = routerComponent;
    }

    public onRouterOutletDeactivate(routerComponent: any) {
        this.viewportService.currentViewportComponent = null;
    }

    ngAfterViewInit(): void {
        if (window.location.hash === "#logout") {
            setTimeout(()=>{
                this.showTopNav = false;
            })
        }

        this.subscriptions.add(
            this.extjsService.onExtJsViewportEvent("contentviewchange").subscribe(
                ([viewport, newView, oldView])=> {
                    const showNav: boolean = newView.xtype !== "igx-loginview" && newView.xtype !== "igx-logoutview";
                    this.showTopNav = showNav;
                })
        );

        this.subscriptions.add(
            this.extjsService.onExtJsEvent("showThirdPartyLicensePopup").subscribe(([isEnterpriseDeployment])=>{
                this.licenseService.showThirdPartyLicensePopup(isEnterpriseDeployment);
            })
        );

        this.subscriptions.add(this.themeService.headerBarThemeChange$
            .subscribe(updatedTheme => this.headerBarThemeChangeHandler(updatedTheme))
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private headerBarThemeChangeHandler(updatedTheme:HeaderBarTheme) {
        if (updatedTheme) {
            // Update Favicon
            let iconUrl:string = updatedTheme.favicon;
            if (iconUrl == null) {
                iconUrl = Settings.FAVICON_URL;
            }
            document.getElementById("faviconLink").setAttribute("href", iconUrl);
        }
    }
}
