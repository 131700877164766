<div class="ig-field-v-ct" [formGroup]="formGroup">
    <label class="detail-header" i18n>Cron Expression Frequency</label>
    <div class="ig-form-section ig-margin-l-b">
        <div class="ig-field-v-ct">
            <label i18n>Cron Expression</label>
            <div class="ig-field-h-ct">
                <div class="ig-form-field ig-w400">
                    <input pInputText formControlName="cronExpression" placeholder="{{(''|IgConstants).REQUIRED}}"
                           [class]="getFieldCls('cronExpression')" type="text"/>
                    <ng-container *ngIf="isFieldInvalid('cronExpression')">
                        <span *ngIf="cronExpression.errors?.invalidCronExpression"
                              class="ig-feedback">{{cronExpression.errors?.invalidCronExpression}}</span>
                    </ng-container>
                </div>
                <a class="externalLink" target="_blank"
                   href="http://www.quartz-scheduler.org/documentation/quartz-2.3.0/tutorials/crontrigger.html"
                   i18n>Cron Expression Support <i class="fa fa-external-link" aria-hidden="true"></i></a>
            </div>
        </div>
    </div>
    <dqp-calendar-range-of-recurrence [style.display]="retentionScheduleMode ? 'none': 'block'" [value]="rangeOfRecurrenceFormValues"></dqp-calendar-range-of-recurrence>
</div>