import {AfterViewInit, Component, Input, ViewChild, ViewEncapsulation} from "@angular/core";
import {ColumnConfig, QuickEditTable, TableConfig, TextColumnConfig} from "@infogix/angular-ui-framework";
import {FormGroup} from "@angular/forms";
import {DevEnvExecutionProperty} from "../../dev-env.model";

@Component({
    selector: "dqp-dev-env-execution-props-table",
    templateUrl: "./dev-env-execution-properties-table.component.html",
    styleUrls: [],
    encapsulation: ViewEncapsulation.None
})
export class DevEnvExecutionPropertiesTableComponent implements AfterViewInit{
     @Input() public tableData:DevEnvExecutionProperty[];
     @Input() public parentFormGroup:FormGroup;
     @Input() public disabled:boolean = false;

     public tableConfig:TableConfig;

     @ViewChild(QuickEditTable) propertiesGrid:QuickEditTable;

    constructor() {
        const colConfigs:ColumnConfig[] = [];
        const textColumnConfig = new TextColumnConfig('name', $localize`Property Name`, $localize`Type a name...`);
        textColumnConfig.unique = true;
        colConfigs.push(textColumnConfig);
        colConfigs.push(new TextColumnConfig('value', $localize`Value`));

        this.tableConfig = new TableConfig(colConfigs);
    }

    public ngAfterViewInit(): void {
        this.parentFormGroup.setControl("executionProperties", this.propertiesGrid.formArray);
        this.parentFormGroup.updateValueAndValidity();
    }

    public get value():DevEnvExecutionProperty[] {
        return this.propertiesGrid.formArray.value;
    }

}