<div class="bom-item-preview" [class.center]="!showItemPreview">
    <dqp-bom-item-preview-toolbar *ngIf="showItemPreview"></dqp-bom-item-preview-toolbar>

    <div id="dqp-bom-item-preview-ct">
        <ng-container *ngIf="showingRedesignedUI">
            <as-split direction="vertical" [gutterSize]="5" [unit]="'pixel'">
                <as-split-area class="top-area">
                    <dqp-view-data-store  *ngIf="selectedStageType === 'DATA_STORE'"
                                          class="stage-preview-ct" [stageId]="selectedStageId"
                                          (activeTabIndexChange)="onDataStoreTabIndexChange($event)"></dqp-view-data-store>
                </as-split-area>
                <as-split-area  #bottomSplitArea [size]="300" [minSize]="48" >
                    <dqp-collaboration-panel [stageId]="selectedStageId"></dqp-collaboration-panel>
                </as-split-area>
            </as-split>
        </ng-container>

        <!-- legacy ExtJS preview page -->
        <div id="dqp-bom-item-preview-extjs-ct" [style.display]="!showItemPreview || showingRedesignedUI  ? 'none': 'block'"></div>

        <!-- landing page when no item is selected -->
        <div class="no-content-ct" *ngIf="!showItemPreview">
            <div class="no-content-icon"></div>
            <div class="no-content-text">{{emptySelectionMsg}}</div>
        </div>
    </div>
</div>