import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    NgModule,
    OnInit,
    Output,
    ViewEncapsulation
} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TableModule} from "primeng/table";
import {SearchFieldModule} from "../search/search-field";
import {PaginatedListModule} from "../paginated-list/paginated-list";
import {PaginatedDataLoader} from "../../data/paginated-data-loader";
import {SelectedItemList} from "./selected-item-list";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {PaginatorModule} from "../paginator/paginator";
import {TooltipModule} from "../tooltip/tooltip";

export const ITEM_SELECTOR_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ItemSelector),
    multi: true
};

@Component({
    selector: 'ig-item-selector',
    templateUrl: "./item-selector.html",
    styleUrls: ["./item-selector.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ITEM_SELECTOR_VALUE_ACCESSOR]
})
export class ItemSelector implements OnInit, ControlValueAccessor {
    @Input() public availableItemsSearchPlaceholder: string = $localize`Search...`;
    @Input() public selectedItemsSearchPlaceholder: string = $localize`Search...`;
    @Input() public availableItemsLabel: string = $localize`Available Items`;
    @Input() public selectedItemsLabel: string = $localize`Selected Items`;

    @Input() public style: any;
    @Input() public styleClass: string;
    @Input() public dataKey: string = "value";
    @Input() public labelKey: string = "label";
    @Input() public tooltipKey:string;

    @Input() public dataLoader: PaginatedDataLoader<any>;
    @Input() public pageSize: number = 25;
    @Input() public pageLinks: number = 5;
    @Input() public disabled: boolean = false;
    @Input() public warningRecordLimit:number = 250;
    @Input() public rowsPerPageOptions: number[] = [25, 50, 100];
    @Input() public showPageLinksOnNewLine:boolean = true;

    @Output() public selectedItemsChange = new EventEmitter<any[]>();

    private _selectedItems: any[];

    private onModelChange: Function = () => {
    };
    private onModelTouched: Function = () => {
    };

    constructor(private cdr: ChangeDetectorRef) {
    }

    public ngOnInit(): void {

    }

    public registerOnChange(fn: any): void {
        this.onModelChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onModelTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public writeValue(obj: any): void {
        this.selectedItems = obj;
        this.cdr.markForCheck();
    }

    @Input()
    public get selectedItems(): any[] {
        return this._selectedItems;
    }

    public set selectedItems(value: any[]) {
        this.updateModel(value);
    }

    private updateModel(newValue: any[]) {
        if (!this.isItemsEqual(this._selectedItems, newValue)) {
            // check against the array content to see if the value is changed. Otherwise, shallow comparison will always
            // return false since the table selection is always returned as a new array.
            this.onModelChange(newValue);
        }
        this._selectedItems = newValue;
    }

    private isItemsEqual(items1: any[], items2: any[]): boolean {
        items1 = items1 ||[];
        items2 = items2 || [];

        if (items1.length != items2.length) {
            return false;
        } else {
            // same size arrays
            for (let i = 0; i < items1.length; i++) {
                const nextItem1 = items1[i];
                const matchingItem2 = items2.find(nextItem2 => {
                    const dataKey = this.dataKey;
                    if (dataKey == null) {
                        return nextItem1 === nextItem2;
                    } else {
                        return nextItem1[dataKey] === nextItem2[dataKey];
                    }
                });
                if (!matchingItem2) {
                    return false;
                }
            }
            return true;
        }
    }
}

@NgModule({
              imports: [CommonModule, TableModule, SearchFieldModule, PaginatedListModule, TooltipModule, PaginatorModule, TooltipModule ],
    exports: [ItemSelector],
    declarations: [ItemSelector, SelectedItemList]
})
export class ItemSelectorModule {
}