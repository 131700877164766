<div class="dqp-stage-item-list">
    <ig-loading [loading]="loading"></ig-loading>
    <p-table #table *ngIf="!loading" [value]="items" dataKey="stageId" [scrollable]="true">
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
            <tr [pSelectableRow]="rowData" (click)="onRowClick(rowData, $event)">
                <td class="stage-icon-col">
                    <span class="stage-icon" [class]="rowData.iconCls"></span>
                </td>
                <td class="stage-path-col" #pathCell (mouseover)="onPathMouseOver(pathCell, rowData)" (mouseleave)="onPathMouseLeave()"
                    tooltipPosition="top"
                    [escape]="false"
                    [showDelay]="1000"
                    [igTooltip]="pathTooltip"
                >
                    <ng-container *ngFor="let nextPathToken of rowData.pathTokens; let i=index">
                        <span *ngIf="i > 0" class="fa fa-angle-right"></span>
                        <span class="dqp-stage-item-label">{{nextPathToken.displayName}}</span>
                    </ng-container>
                </td>
                <td class="col-btn">
                    <button type="button" class="ig-icon ig-button"
                            [igTooltip]="deleteButtonTooltip" tooltipPosition="top" tooltipStyleClass="ig-noWrap"
                            (click)="onDeleteButtonClick(rowData, $event)"><i class="fa fa-trash"></i></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>