export enum AuthType {
    INTERNAL = "INTERNAL",
    AUTH0 = "AUTH0",
    SAML = "SAML",
    OIDC = "OIDC"
}

export interface Principal {
    isOrgAdmin: boolean;
    isSysAdmin: boolean;
    email: string;
    displayName: string;
    orgId: number;
    profileImageUrls: ProfileImageUrls;
    authType:AuthType;
    orgTimeZone:string;
}

export interface ProfileImageUrls {
    low: string;
    medium: string;
    high: string;
}