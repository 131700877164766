import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {AjaxService} from "../ajax/ajax.service";
import {Injectable} from "@angular/core";

@Injectable({providedIn:"root"})
export class LocaleService {
    private localeInfosCache: LocaleInfo[] = null;

    constructor(private ajaxService: AjaxService) {
    }

    public listLocales(): Observable<LocaleInfo[]> {
        if (this.localeInfosCache == null) {
            const graphqlQuery = `query {
                                    systemInfo {
                                      locales {
                                        id
                                        displayName
                                      }
                                    }
                                  }`;
            return this.ajaxService.query(graphqlQuery)
                .pipe(
                    map((response) => {
                        const locales = response.data.systemInfo.locales;
                        const localeInfos: LocaleInfo[] = [];
                        for (let locale of locales) {
                            const localeInfo: LocaleInfo = {id: locale.id, displayName: locale.displayName};
                            localeInfos.push(localeInfo);
                        }
                        this.localeInfosCache = localeInfos;
                        return this.localeInfosCache
                        }
                    )
                );
        } else {
            return of(this.localeInfosCache);
        }
    }


}

export class LocaleInfo {
    id: string;
    displayName: string;
}
