import {AjaxService} from "../ajax/ajax.service";
import {SystemInfo} from "./system-info.model";
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class SystemInfoService {
    private systemInfo: SystemInfo = null;

    constructor(private ajaxService: AjaxService) {
    }

    public readSystemInfo(): Observable<SystemInfo> {
        if (this.systemInfo == null) {
            const graphqlQuery = `query {
                                      systemInfo {
                                        aWSCloudDeployment
                                        appDeploymentMode
                                        awsCloudDeployment
                                        azureCloudDeployment
                                        bigDataStoreType
                                        buildDateStr
                                        buildVersion
                                        cloudType
                                        deploymentHostUrl
                                        deploymentSecure
                                        enterpriseMode
                                        fullEnterpriseMode
                                        hadoopVendor
                                        hadoopVendorVersion
                                        liteEnterpriseMode
                                        pivotGridLicense
                                        pivotGridVendor
                                        samlEnabled
                                        singleTenant
                                        solutionContextName
                                        solutionPatches
                                        solutionVersion
                                        dataProcEnabled
                                        kubernetesDeployment
                                        gcpCloudDeployment
                                      }
                                }`;
            return this.ajaxService.query(graphqlQuery)
                       .pipe(
                           map((response) => {
                               this.systemInfo = new SystemInfo(response.data.systemInfo);
                               return this.systemInfo;
                           })
                       );
        } else {
            return of(this.systemInfo);
        }
    }

}
