<h1 class="ig-title" i18n>Favorites</h1>
<section class="dqp-favorites-panel">
    <div class="coach-text" i18n *ngIf="!loading && (favoriteItems?.length <= 0)">
        Use the <span class="fa fa-star"></span> button to add pages to your Favorites, listing them here and in the
        Pipeline-Favorites menu for quick access.
    </div>
    <dqp-stage-item-list
            [items]="favoriteItems"
            [loading]="loading"
            (deleteClick)="onItemDelete($event)"
            (itemClick)="onItemClick($event)"
            [deleteButtonTooltip]="deleteButtonTooltip"
    ></dqp-stage-item-list>
</section>