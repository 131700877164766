<div class="login-popup">
    <ng-container *ngIf="errorMessage">
        <p-message severity="error" [text]="errorMessage" [style]="'width:100%'"></p-message>
    </ng-container>
    <div class="ig-form-section">

        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" style="width:100%">
            <div class="ig-form-section">
                <div class="ig-field-v-ct ig-stretch">
                    <label i18n>Username</label>
                    <div class="ig-form-field ig-grow">
                        <input pInputText formControlName="username"
                               autocomplete="username"
                               placeholder="{{(''|IgConstants).REQUIRED}}"
                               [class.required]="username.invalid" type="text"/>
                    </div>
                </div>
                <div class="ig-field-v-ct ig-stretch">
                    <label i18n>Password</label>
                    <div class="ig-form-field ig-grow">
                        <input type="password" pPassword autoFocus
                               autocomplete="current-password"
                               placeholder="{{(''|IgConstants).REQUIRED}}"
                               [feedback]="false"
                               class="ig-required"
                               [class.ig-required]="password.invalid"
                               formControlName="password" pInputText/>
                    </div>
                </div>

                <div class="p-dialog-footer p-widget-content" style="width: 100%">
                    <div class="ig-row ig-w-100pc">
                        <button type="button" igButton (click)="onCancel()" i18n-label label="Cancel"></button>
                        <span class="ig-grow"></span>
                        <button type="submit" igButton i18n-label label="Login" [disabled]="this.formGroup.invalid"
                                class="ig-button-primary"></button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>